import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const _protocols1 = [ 
  { value: "", label: "" },
  { value: "0", label: "TCP" },
  { value: "1", label: "UDP" },
  { value: "2", label: "MQTT" },
];
const _protocols2 = [ 
  { value: "", label: "" },
  { value: "0", label: "TCP" },
  { value: "2", label: "MQTT" },
];

const PanelGPRSServerSetup = ({token, deviceImei, onMQTTSelected, commandResponse}) => {
  
  const [grsHost, setGrsHost] = useState("");
  const [grsPort, setGrsPort] = useState("");
  const [grsProtocol, setGrsProtocol] = useState("");
  const [tlsEncryption, setTlsEncryption] = useState(false);
  
  const [updatedDate, setUpdatedDate] = useState("");

  const [protocols, setProtocols] = useState(_protocols1);

  const sendGPRSServerSetup = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.GprsServerSetup,
      params: {
        host: grsHost,
        port: grsPort,
        protocol: grsProtocol,
        tlsEncryption: tlsEncryption ? 1 : 0
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.GprsServerSetup,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setGrsHost(response.host);
    setGrsPort(response.port);
    setGrsProtocol(response.protocol);
    setTlsEncryption(parseInt(response.tlsEncryption) == 1);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.GprsServerSetup) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  useEffect(() => {
    setProtocols(tlsEncryption ? _protocols2 : _protocols1);
    if (tlsEncryption && grsProtocol == 1) {
      setGrsProtocol("0");
    }
  }, [tlsEncryption]);

  useEffect(() => {
    onMQTTSelected(grsProtocol == 2);
  }, [grsProtocol]);

  return (
    <CommandSettingPanel
      title="GPRS Server Setup"
      description="Setup server to send data by GPRS link. Note: after changing this setting device will be disconnected from current server."
      date={updatedDate}
      onSendAction={sendGPRSServerSetup}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <TextInput
            name="Host"
            tooltip=""
            type="text"
            value={grsHost}
            onChange={(value) => setGrsHost(value)} />
          <TextInput
            name="Port"
            tooltip=""
            type="number"
            value={grsPort}
            onChange={(value) => setGrsPort(value)} />
          <SelectBox 
            name="Server Protocol"
            value={grsProtocol}
            onChange={(value) => setGrsProtocol(value)}
            options={protocols}
            />
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>TLS Encryption</span>
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" className="btn-check" name="btnradio_gs" id="gs_btnradio1" checked={!tlsEncryption} onClick={() => setTlsEncryption(false)} />
              <label className="btn " htmlFor="gs_btnradio1" style={{ width: 70 }}>None</label>

              <input type="radio" className="btn-check" name="btnradio_gs" id="gs_btnradio2" checked={tlsEncryption} onClick={() => setTlsEncryption(true)} />
              <label className="btn " htmlFor="gs_btnradio2">TLS/DRLS</label>
            </div>
          </div>
        </div>
      )} />
  );
};

export default PanelGPRSServerSetup;
