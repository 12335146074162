import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import CustomDatePicker from "../commons/datepicker/CustomDatePicker";

import "./acs.css";
import { ACSUserTypes, ACSVerifyModes } from "../../common/common";
import { getBytesFromString, getDateValueStr, getPacketIntValue, getPacketStrValue, getValueStr } from "../../utils/globals";
import { sendCommandMsg, socket } from "../../socket";
import { notification, Select } from "antd";
import { uploadAvatar, upsertACSUser } from "../../services/axios";

const UserEditResult = {
  '00': 'Successfully processed',
  '02': 'Unknown error has occurred',
  '03': 'Not a registered user',
  '04': 'Check sum error',
  '05': 'Other packet error',
  '06': 'Already registered User ID',
  '08': 'Unknown command',
  '0a': 'Over max registered user',
  '0b': 'Over max User ID',
  '0c': 'Already registered Card NO',
  '0d': 'over max Bypass Time Zone Level',
  '13': 'FP Templates size mismatch',
  '14': 'Registered FP number wrong',
  '30': 'Busy',
};

const FingerScanResult = {
  '00': 'Successfully processed',
  '04': 'Check sum error',
  '05': 'Other packet error',
  '08': 'Unknown command',
  '09': 'User Data Error',
  '15': 'FP Scan Timeout Fail',
  '16': 'Scan Fail / Duplicate finger error',
  '30': 'Terminal is busy',
}

const UserEdit = ({ selectedVehData, selectedUser, onClose }) => {

  const [image, setImage] = useState(selectedUser ? selectedUser.image : "");
  const [userName, setUserName] = useState(selectedUser ? selectedUser.userName : "");
  const [userId, setUserId] = useState(selectedUser ? selectedUser.userId : "");
  const [cardNo, setCardNo] = useState(selectedUser ? selectedUser.cardNo : "");
  const [status, setStatus] = useState(selectedUser ? selectedUser.status : 1);
  const [type, setType] = useState(selectedUser ? selectedUser.type : 0);
  const [verifyMode, setVerifyMode] = useState(selectedUser ? selectedUser.verifyMode : 1);
  const [email, setEmail] = useState(selectedUser ? selectedUser.email : '');
  const [mobile, setMobile] = useState(selectedUser ? selectedUser.mobile : '');
  const [fpCount, setFpCount] = useState(selectedUser ? selectedUser.fpCount : 0);
  const [expireStatus, setExpireStatus] = useState(selectedUser ? selectedUser.expireStatus : 0);
  const [startDate, setStartDate] = useState(selectedUser ? new Date(selectedUser.expireStart) : null);
  const [endDate, setEndDate] = useState(selectedUser ? new Date(selectedUser.expireEnd) : null);

  const [fpTempSize, setFpTempSize] = useState(selectedUser ? selectedUser.fpTempSize : 384);
  const [fpTempData, setFpTempData] = useState(selectedUser ? selectedUser.fpTempData : "");

  const [fpData1, setFpData1] = useState(null);
  const [fpData2, setFpData2] = useState(null);
  const [scanningFpNo, setScanningFpNo] = useState(0);

  const [updateInfo, setUpdateInfo] = useState(null);

  useEffect(() => {
    const onUpdateCommandResponse = async (cmdData) => {
      console.log("🚀 ~ onUpdateCommandResponse ~ cmdData:", cmdData);

      const { deviceImei, matchingId, response, result } = cmdData;
      if (deviceImei != selectedVehData.deviceImei) {
        return;
      }

      if (matchingId == '07') {
        // save result

        if (result == '00') {
          notification.success({ description: UserEditResult['00'] });

          console.log("🚀 ~ onUpdateCommandResponse ~ info:", updateInfo)
          await upsertACSUser(updateInfo);

        } else {
          let msg = UserEditResult[result];
          if (!msg) { msg = `Unknown Error : ${result}!`; }
          notification.error({ description: msg });
        }
      } else if (matchingId == "51") {
        // card scanning

        const logCount = getPacketIntValue(response, 0, 1);
        for (let i = 0; i < logCount; i++) {
          const logData = getPacketStrValue(response, i * 32 + 1, 32);
          if (logData.length == 0) {
            break;
          }

          // get cardno
          const logUserId = getPacketIntValue(logData, 14, 4);
          const logCardNo = getPacketIntValue(logData, 18, 8);
          console.log("🚀 ~ onUpdateCommandResponse ~ logCardNo:", logUserId, logCardNo)
          if (logCardNo > 0) {
            setCardNo(logCardNo);
            break;
          }
        }
      } else if (matchingId == "1f") {
        // scan finger result

        if (result == '00') {
          let numberOfFP = getPacketIntValue(response, 0, 1);
          let templateSize = getPacketIntValue(response, 3, 2);
          const fpData = {
            numberOfFP: numberOfFP,
            fp1Score: getPacketIntValue(response, 1, 1),
            fp2Score: getPacketIntValue(response, 2, 1),
            templateSize: templateSize,
            templates: getPacketStrValue(response, 5, templateSize * numberOfFP),
          };
          console.log("🚀 ~ onUpdateCommandResponse ~ fpData:", fpData)
          if (scanningFpNo == 0) {
            setFpData1(fpData);
          } else {
            setFpData2(fpData);
          }
          setFpTempSize(templateSize);
          setFpCount(0);

          notification.success({ description: "Fingerprint successfully scanned!" })
        } else {
          let msg = FingerScanResult[result];
          if (!msg) { msg = `Unknown Error : ${result}!`; }
          notification.error({ description: msg });
        }
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);

    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, [selectedVehData, scanningFpNo, updateInfo]);

  const handleScanFinger = async (index) => {
    setScanningFpNo(index);
    sendCommandMsg(null, selectedVehData?.deviceImei, "1F:000000000001");
  }

  const handleSave = async () => {
    const results = [];
    let newFpCount = 0;
    let fpData = "";
    if (fpData1 != null) {
      newFpCount++;
      fpData += getValueStr(fpData1.templates, fpTempSize, false);
    }
    if (fpData2 != null) {
      newFpCount++;
      fpData += getValueStr(fpData2.templates, fpTempSize, false);
    }
    if (fpData == "") {
      fpData = fpTempData;
      newFpCount = fpCount;
    }

    results.push(
      getValueStr(userId, 4),
      getValueStr(1, 1),      // 0 - don't overwrite, 1 - overwrite
      getValueStr(cardNo, 8),
      getValueStr(0, 10),
      getBytesFromString(userName, 31),
      getValueStr(expireStatus, 1),
      getDateValueStr(startDate),
      getDateValueStr(endDate),
      getValueStr(status, 1),
      getValueStr(type, 1),
      getValueStr(1, 1),        // 1 - free group, 0 - disallowed group
      getValueStr(0, 3),        // 1 - free group, 0 - disallowed group
      getValueStr(verifyMode, 1),
      getValueStr(0, 20),
      getValueStr(newFpCount, 1),
      getValueStr(fpTempSize, 2),
      fpData,
    );

    let info = {
      device: selectedVehData.deviceImei,
      userName, userId, image, cardNo, status, type, verifyMode, email, mobile,
      expireStatus, expireStart: startDate, expireEnd: endDate,
      fpCount: newFpCount, fpTempSize, fpTempData: fpData
    };
    setUpdateInfo(info);

    sendCommandMsg(null, selectedVehData?.deviceImei, "07:" + results.join(""));
  }

  const onChangeImage = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const result = await uploadAvatar(selectedFile);
      if (result.status == 200) {
        setImage(result.data.filePath);
      }
    }
  }

  return (
    <div className="acs-panel">
      <div className="panel-title mb-5">
        {selectedVehData.vehicleName} {'>'} {selectedUser ? 'Edit User' : 'Add User'}
      </div>

      <div className="panel-body add-user-main">
        <div className="d-flex justify-content-start align-items-center">
          <div className='d-flex justify-content-center position-relative'>
            <input type='file' className='position-absolute personfile' accept='image/*' onChange={onChangeImage} />
            <img
              crossOrigin='*' src={image ? process.env.REACT_APP_URL + '/' + image : '/assets/common_user.png'} alt='none'
              className='person-add-user object-fit-cover' style={{ width: "10rem", height: "10rem" }} />
          </div>
          <div className="ms-5 rbold fw-bold" style={{ fontSize: "1.8rem" }}>{userName}</div>
        </div>

        <div className='add-user-div1 d-flex justify-content-start flex-column px-4 mx-5'>
          <div className='add-input-container d-flex justify-content-evenly mb-4 gap-3'>
            <div className='d-flex flex-column gap-3'>
              <label>Name</label>
              <input className='normal-input' type='text' value={userName} onChange={e => setUserName(e.target.value)} />
            </div>
            <div className='d-flex flex-column gap-3'>
              <label>User ID</label>
              <input className='normal-input' type='number' value={userId} onChange={e => setUserId(e.target.value)} />
            </div>
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-4 gap-3'>
            <div className='d-flex flex-column gap-3'>
              <label>Email</label>
              <input className='normal-input' type='text' value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className='d-flex flex-column gap-3'>
              <label>Mobile No.</label>
              <input className='normal-input' type='text' value={mobile} onChange={e => setMobile(e.target.value)} />
            </div>
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-4 gap-3'>
            <div className='d-flex flex-column gap-3'>
              <label>User Type</label>
              <Select
                  className="normal-input"
                  placeholder=""
                  options={ACSUserTypes.map((label, index) => ({
                    label: label, 
                    value: index
                  }))}
                  value={type}
                  onChange={value => setType(value)}
                />
            </div>
            <div className='d-flex flex-column gap-3'>
              <label>Card No.</label>
              <input className='normal-input' type='number' value={cardNo} onChange={e => setCardNo(e.target.value)} />
            </div>
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-4 gap-3'>
            <div className='d-flex flex-column gap-3'>
              <label>Verify Mode</label>
              <Select
                  className="normal-input"
                  placeholder=""
                  options={ACSVerifyModes.map((label, index) => ({
                    label: label, 
                    value: index
                  }))}
                  value={verifyMode}
                  onChange={value => setVerifyMode(value)}
                />
            </div>
            <div className='d-flex flex-column gap-3'>
              <label>Expiry</label>
              <Select
                  className="normal-input"
                  placeholder=""
                  options={[{
                    label: "Enable", 
                    value: 1
                  }, {
                    label: "Disable", 
                    value: 0
                  }]}
                  value={expireStatus}
                  onChange={value => setExpireStatus(value)}
                />
            </div>
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-4 gap-3'>
            <div className='d-flex flex-row justify-content-center gap-5 normal-input border-0 h-auto'>
              <div className="d-flex flex-column align-items-center gap-3">
                <div className="red-black">Scan Finger-1</div>
                <div className="btn-fpscan" onClick={() => handleScanFinger(0)}>
                  <img src="/assets/fingerprint.svg" alt="fp" className="fp" />
                  {(fpData1 || fpCount > 0) &&
                    <img src="/assets/checkmark.svg" alt="fp" className="check" />
                  }
                </div>
              </div>
              <div className="d-flex flex-column align-items-center gap-3">
                <div className="red-black">Scan Finger-2</div>
                <div className="btn-fpscan" onClick={() => handleScanFinger(1)}>
                  <img src="/assets/fingerprint.svg" alt="fp" className="fp" />
                  {(fpData2 || fpCount > 1) &&
                    <img src="/assets/checkmark.svg" alt="fp" className="check" />
                  }
                </div>
              </div>
            </div>
            <div className='d-flex flex-column gap-3 normal-input border-0 ps-0 h-auto'>
              {expireStatus == 1 &&
                <>
                  <label>Validity</label>
                  <div className="d-flex flex-column flex-md-row justify-content-between gap-3 w-100">
                    <div className="position-relative">
                      <CustomDatePicker
                        placeholderText="From"
                        selectedDate={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                      />
                      <FontAwesomeIcon
                        className="position-absolute top-0 end-0 mt-3 me-3"
                        icon={faCalendarDays} />
                    </div>
                    <div className="position-relative">
                      <CustomDatePicker
                        placeholderText="To"
                        selectedDate={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                      />
                      <FontAwesomeIcon
                        className="position-absolute top-0 end-0 mt-3 me-3"
                        icon={faCalendarDays} />
                    </div>
                  </div>
                </>
              }
            </div>
          </div>

          <div className="w-100 d-flex justify-content-center gap-4 mt-4">
            <button className="tab-button" onClick={onClose}>Cancel</button>
            <button className="tab-button" onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEdit;
