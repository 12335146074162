import React, { useEffect, useState } from "react";
import { CommandPriorities, cmdType, getCommandPhones } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelTripParameters = ({token, deviceImei, commandResponse, gsmNumbers}) => {
  
  const [trip_priority, setTripPriority] = useState("");
  const [trip_records_check, setTripRecordsCheck] = useState(false);
  const [trip_mode, setTripMode] = useState("");
  const [trip_start_speed, setTripStartSpeed] = useState("");
  const [trip_off_timeout, setTripOffTimeout] = useState("");
  const [trip_send_sms_check, setTripSendSmsCheck] = useState(false);
  const [trip_eco_score_alEvent, setTripEcoScoreAlEvent] = useState("");
  const [trip_remember_ibtn_check, setTripRememberIBtncheck] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [smsText, setSmsText] = useState("");
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendTripScenarioParameters = async () => {
    return sendCommonRequest(cmdType.TripScenarioParam, {
      priority: trip_priority,
      evRec: trip_records_check ? "1" : "0",
      mode: trip_mode,
      startSp: trip_start_speed,
      tout: trip_off_timeout,
      eco: trip_eco_score_alEvent,
      remember: trip_remember_ibtn_check ? "1" : "0",
      phoneNo: phoneNumber,
      sms: smsText,
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.TripScenarioParam,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setTripPriority(response.priority);
    setTripRecordsCheck(parseInt(response.evRec) === 1);
    setTripMode(response.mode);
    setTripStartSpeed(response.startSp);
    setTripOffTimeout(response.tout);
    setTripEcoScoreAlEvent(response.eco);
    setTripRememberIBtncheck(parseInt(response.remember) === 1);
    setTripSendSmsCheck(parseInt(response.phoneNo) > 0);
    setPhoneNumber(response.phoneNo);
    setSmsText(response.sms);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.TripScenarioParam) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Trip scenario parameters"
      date={updatedDate}
      onSendAction={sendTripScenarioParameters}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Priority"
            value={trip_priority}
            onChange={(value) => setTripPriority(value)}
            options={CommandPriorities}
            tooltip={"Towing detection scenario priority"}
          />
          <div className="input-element position-relative border-0 d-flex justify-content-start mb-3">
            <Switch
              value={trip_records_check}
              onChange={(checked) => setTripRecordsCheck(checked)}
              suffix={"Eventual Records"}
            />
          </div>
          <SelectBox
            name="Mode"
            value={trip_mode}
            onChange={(value) => setTripMode(value)}
            options={[ 
              { value: "", label: "" },
              { value: "0", label: "Continuous" },
              { value: "1", label: "Between records" },
            ]}
          />
          <TextInput
            name="Start Speed"
            type="number"
            value={trip_start_speed}
            onChange={(value) => setTripStartSpeed(value)} />
          <TextInput
            name="Ignition Off Timeout"
            type="number"
            value={trip_off_timeout}
            onChange={(value) => setTripOffTimeout(value)} />
          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={trip_send_sms_check}
              onChange={(checked) => setTripSendSmsCheck(checked)}
              prefix={<span className="pe-5 me-5">Send SMS</span>}
              suffix={"ON"}
            />
          </div>
          {trip_send_sms_check && (
            <>
              <SelectBox
                name="Phone Number"
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                options={getCommandPhones(gsmNumbers)}
              />
              <TextInput
                name="SMS Text"
                type="text"
                value={smsText}
                onChange={(value) => setSmsText(value)} />
            </>
          )}
          <TextInput
            name="Eco Score Allowed Events"
            type="number"
            value={trip_eco_score_alEvent}
            onChange={(value) => setTripEcoScoreAlEvent(value)} />
          <div className="input-element position-relative border-0 d-flex justify-content-start mb-3">
            <Switch
              value={trip_remember_ibtn_check}
              onChange={(checked) => setTripRememberIBtncheck(checked)}
              suffix={"Remember iButton"}
            />
          </div>
        </div>
      )} />
  );
};

export default PanelTripParameters;
