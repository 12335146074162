import React, { useEffect, useState } from "react";
import { CommandDoutControls, CommandPriorities, cmdType, getCommandPhones } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import Switch from "../../commons/switch/Switch";
import TextInput from "../../commons/input/TextInput";
import { sendCommandMsg } from "../../../socket";

const PanelImmobilizer = ({token, deviceImei, commandResponse, gsmNumbers}) => {
  
  const [priority, setPriority] = useState("");
  const [eventOnly, setEventOnly] = useState(false);
  const [outputControl, setOutputControl] = useState("");
  const [listCheck, setListCheck] = useState("");
  const [sendSms, setSendSms] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [smsText, setSmsText] = useState("");
  const [offTimeout, setOffTimeout] = useState("");
  const [noAuthTimeout, setNoAuthTimeout] = useState("");

  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendAction = async () => {
    return sendCommonRequest(cmdType.Immobilizer, {
      priority: priority,
      eventOnly: eventOnly ? "1" : "0",
      outputControl: outputControl,
      listCheck: listCheck,
      sendSms: sendSms ? "1" : "0",
      phoneNo: sendSms ? phoneNumber : "0",
      sms: sendSms ? smsText : "",
      offTimeout: offTimeout,
      noAuthTimeout: noAuthTimeout,
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.Immobilizer,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setPriority(response.priority);
    setEventOnly(parseInt(response.eventOnly) === 1);
    setOutputControl(response.outputControl);
    setListCheck(response.listCheck);
    setSendSms(parseInt(response.phoneNo) > 0);
    setPhoneNumber(response.phoneNo);
    setSmsText(response.sms);
    setOffTimeout(response.offTimeout);
    setNoAuthTimeout(response.noAuthTimeout);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.Immobilizer) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Immobilizer"
      description="Immobilizer"
      date={updatedDate}
      onSendAction={sendAction}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Scenario Priority"
            value={priority}
            onChange={(value) => setPriority(value)}
            options={CommandPriorities}
          />
          <div className="input-element border-0 position-relative d-flex justify-content-start mb-3">
            <Switch
              value={eventOnly}
              onChange={(checked) => setEventOnly(checked)}
              suffix={"Event Only"}
            />
          </div>
          <SelectBox
            name="Output Control"
            value={outputControl}
            onChange={(value) => setOutputControl(value)}
            options={CommandDoutControls}
          />
          <SelectBox
            name="List Check For Authorization"
            value={listCheck}
            onChange={(value) => setListCheck(value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "OneWire" },
              { value: "1", label: "Beacon" },
            ]}
          />
          <TextInput
            name="Ignition Off timeout"
            type="number"
            suffix="s"
            value={offTimeout}
            options={{ min: 1, max: 65535 }}
            onChange={(value) => setOffTimeout(value)} />
          <TextInput
            name="No Authentication timeout"
            type="number"
            suffix="s"
            value={noAuthTimeout}
            options={{ min: 0, max: 65535 }}
            onChange={(value) => setNoAuthTimeout(value)} />
          <div className="input-element border-0 position-relative d-flex justify-content-start mb-3">
            <Switch
              value={sendSms}
              onChange={(checked) => setSendSms(checked)}
              prefix={<span className="pe-5 me-5">Send SMS</span>}
              suffix={"ON"}
            />
          </div>
          {sendSms && (
            <>
              <SelectBox
                name="Phone Number"
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                options={getCommandPhones(gsmNumbers)}
              />
              <TextInput
                name="SMS Text"
                type="text"
                value={smsText}
                onChange={(value) => setSmsText(value)} />
            </>
          )}
        </div>
      )} />
  );
};

export default PanelImmobilizer;
