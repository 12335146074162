import React, { useEffect, useState } from "react";

import "./acs.css";
import { Pagination, Select } from "antd";
import { sendCommandMsg, socket } from "../../socket";
import { formatDateTimeSec, getACSVerifySources, getPacketDateReverseValue, getPacketDateValue, getPacketIntValue, getPacketStrValue } from "../../utils/globals";
import { ACSEventCodes, ACSLogInOutIndications } from "../../common/common";
import { useLocation } from "react-router-dom";
import CustomDatePicker from "../commons/datepicker/CustomDatePicker";
import { getACSUserList } from "../../services/axios";
import { useMediaQuery } from "@mui/material";

const AccessLogs = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const location = useLocation();
  const selectedVehData = location.state;

  const [page, setPage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [allDataList, setAllDataList] = useState([]);
  const [filteredDataList, setFilteredDataList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [searchFold, setSearchFold] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [searchID, setSearchID] = useState("");
  const [searchAccessType, setSearchAccessType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (selectedVehData) {
      loadData();
    }

    const onUpdateCommandResponse = async (cmdData) => {
      // console.log(cmdData);

      const { deviceImei, matchingId, response } = cmdData;
      if (deviceImei != selectedVehData.deviceImei) {
        return;
      }

      if (matchingId == "41") {
        const items = [];
        const count = getPacketIntValue(response, 0, 4);
        for (let i = 0; i < count; i++) {
          let itemData = getPacketStrValue(response, i * 32 + 4, 32);
          if (itemData.length == 0) {
            break;
          }

          let item = {
            name: '',
            date: getPacketDateReverseValue(itemData, 0, true),
            inOut: getPacketIntValue(itemData, 6, 1),
            verifySource: getPacketIntValue(itemData, 7, 1),
            eventCode: getPacketStrValue(itemData, 8, 1),
            door: getPacketIntValue(itemData, 9, 1),
            userId: getPacketIntValue(itemData, 10, 4),
            logIndex: getPacketIntValue(itemData, 14, 4),
            cardId: getPacketIntValue(itemData, 18, 8),
            keyIndex: getPacketIntValue(itemData, 26, 2),
          };
          items.push(item);
        }
        setAllDataList(items.reverse());
        setPage(1);
      }
    }
    socket.on('updateCommandResponse', onUpdateCommandResponse);

    return () => {
      socket.off('updateCommandResponse', onUpdateCommandResponse);
    };
  }, [selectedVehData]);

  useEffect(() => {
    setFilteredDataList(
      allDataList.filter(item => (
        (!searchName || getUserName(item.userId, userList).toLowerCase().includes(searchName.toLowerCase())) &&
        (!searchID || String(item.userId).toLowerCase().includes(searchID.toLowerCase())) &&
        (!startDate || item.date >= startDate) && (!endDate || item.date <= endDate) &&
        (!searchAccessType || item.verifySource == searchAccessType)
      ))
    );
  }, [allDataList, searchName, searchID, searchAccessType, startDate, endDate]);

  useEffect(() => {
    let items = [];
    let start = (page - 1) * 10;
    let end = Math.min(start + 10, filteredDataList.length);
    for (let i = start; i < end; i++) {
      items.push(filteredDataList[i]);
    }
    setDataList(items);
  }, [page, filteredDataList]);

  const loadData = async () => {
    const deviceImei = selectedVehData.deviceImei;
    sendCommandMsg(null, deviceImei, "41");

    const res = await getACSUserList({
      device: deviceImei,
      pageNum: 1, pageSize: 10,
      status: ''
    });
    if (res.status == 200) {
      setUserList(res.data.result);
    }
  }

  const getUserName = (userId, list) => {
    if (!list) {
      return '-';
    }
    const info = list.find(item => item.userId == userId);
    if (info) {
      return info.userName;
    }
    return '-';
  }

  const handlePageChange = async (pageNum, pageSize) => {
    setPage(pageNum);
  }

  const handleClear = () => {
    setSearchName("");
    setSearchID("");
    setStartDate(null);
    setEndDate(null);
    setSearchAccessType("");
  }

  return (
    <div className="acs-panel">
      <div className="panel-title">
        {selectedVehData.vehicleName} {'>'} Access Logs
      </div>

      <div className="panel-body">
        {!isMobile ?
          <>
            <div className="subsub1-sub2-div1 py-2 mb-4">
              <div className="sub1-subsub1-sub2-div1 my-2">
                <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
                <span>Search Log</span>
              </div>
              <div className="sub2-subsub1-sub2-div1">
                <input
                  className="field-input"
                  type="text"
                  placeholder="Name"
                  value={searchName}
                  onChange={e => setSearchName(e.target.value)}
                />
                <input
                  className="field-input"
                  type="numder"
                  placeholder="ID"
                  value={searchID}
                  onChange={e => setSearchID(e.target.value)}
                />
                <Select
                  className="field-select"
                  placeholder=""
                  options={[
                    { label: "Access Type", value: "" },
                  ]}
                  value={searchAccessType}
                  onChange={value => setSearchAccessType(value)}
                />
                <div className="field-input date-picker-input">
                  <CustomDatePicker
                    placeholderText="From"
                    selectedDate={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                  />
                  <img src="/assets/calender.png" alt="cal" />
                </div>
                <div className="field-input date-picker-input">
                  <CustomDatePicker
                    placeholderText="To"
                    selectedDate={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                  <img src="/assets/calender.png" alt="cal" />
                </div>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            </div>

            <div className="info-area w-100 accesslogs mb-3">
              <div className="table-header mb-2">
                <div>No.</div>
                <div>Name</div>
                <div>UserID</div>
                <div>Date & Time</div>
                <div>IN/OUT</div>
                <div>Door</div>
                <div>Access Type</div>
              </div>
              <div className="table-body d-flex flex-column gap-2 overflow-auto">
                {dataList.map((item, idx) =>
                  <div key={idx}>
                    <div>{(page * 10) + idx - 9}.</div>
                    <div>{getUserName(item.userId, userList)}</div>
                    <div>{item.userId > 0 ? item.userId : '-'}</div>
                    <div>{formatDateTimeSec(item.date)}</div>
                    <div>{ACSLogInOutIndications[item.inOut]}</div>
                    <div>{item.door}</div>
                    <div>
                      {getACSVerifySources(item.verifySource)}
                      {item.eventCode != '00' ? ': ' + ACSEventCodes[item.eventCode] : ''}
                      {item.cardId ? ` (${item.cardId})` : ''}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </> :
          <>
            <div className="bg-white d-flex flex-column py-2 px-4 w-100 mb-4">
              <div className="sub1-subsub1-sub2-div1 d-flex align-items-center p-2" onClick={() => setSearchFold(!searchFold)}>
                <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
                <span>Search Logs</span>
                <img
                  className="ms-auto accordion"
                  style={!searchFold ? { transform: "rotate(180deg)" } : { transform: "none" }}
                  src="/assets/arrow-down.png"
                  alt="Arrow Down"
                />
              </div>
              {!searchFold && (
                <div className="sub2-subsub1-sub2-div1 d-flex flex-column px-0">
                  <div className="d-flex w-100">
                    <div className="col-6 px-1">
                      <input
                        className="field-input"
                        type="text"
                        placeholder="Name"
                        value={searchName}
                        onChange={e => setSearchName(e.target.value)}
                      />
                    </div>
                    <div className="col-6 px-1">
                      <input
                        className="field-input"
                        type="numder"
                        placeholder="ID"
                        value={searchID}
                        onChange={e => setSearchID(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="d-flex w-100">
                    <div className="col-6 px-1">
                      <div className="field-input date-picker-input">
                        <CustomDatePicker
                          placeholderText="From"
                          selectedDate={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={endDate}
                        />
                        <img src="/assets/calender.png" alt="cal" />
                      </div>
                    </div>
                    <div className="col-6 px-1">
                      <div className="field-input date-picker-input">
                        <CustomDatePicker
                          placeholderText="To"
                          selectedDate={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                        <img src="/assets/calender.png" alt="cal" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex w-100">
                    <div className="col-6 px-1">
                      <Select
                        className="field-select"
                        placeholder=""
                        options={[
                          { label: "Access Type", value: "" },
                        ]}
                        value={searchAccessType}
                        onChange={value => setSearchAccessType(value)}
                      />
                    </div>
                    <div className="col-6 px-1 d-flex justify-content-end">
                      <div
                        className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                        onClick={() => handleClear()}
                      >
                        <img src="/assets/clear.svg" alt="none" />
                        <button>Clear</button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex flex-column gap-2 w-100">
              {dataList.map((item, index) =>
                <div id="import-users" key={index}>
                  <div className="subsub1-sub2-users-div2 d-flex align-items-center py-2">
                    <div className="d-flex flex-column col-11 px-2">
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Name</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {getUserName(item.userId, userList)}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">UserID</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item.userId > 0 ? item.userId : '-'}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Date & Time</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {formatDateTimeSec(item.date)}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">IN/OUT</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {ACSLogInOutIndications[item.inOut]}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Door</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item.door}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Access Type</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {getACSVerifySources(item.verifySource)}
                          {item.eventCode != '00' ? ': ' + ACSEventCodes[item.eventCode] : ''}
                          {item.cardId ? ` (${item.cardId})` : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        }
        <div className="text-center pt-3">
          <Pagination size="small" total={filteredDataList.length} onChange={handlePageChange} current={page} showSizeChanger={false} />
        </div>
      </div>
    </div>
  );
};

export default AccessLogs;
