import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getCountries } from "../../../utils/globals";
import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import { Checkbox, Select, notification } from "antd";
import { updateCompany, uploadAvatar } from "../../../services/axios";
import { VehicleTypes, isIgnoreVehicleType } from "../../../common/common";
import { useMediaQuery } from "@mui/material";

const EditCompany = () => {
  const getData = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const CountryData = getCountries();

  const [phoneNumber, setPhoneNumber] = useState(getData.state.companyInfo?.mobile);
  const [logoPath, setLogoPath] = useState(getData.state.companyInfo?.logo);
  const [formData, setFormData] = useState({
    name: getData.state.companyInfo.name,
    owner: getData.state.companyInfo.owner,
    email: getData.state.companyInfo.email,
    country: getData.state.companyInfo.country,
    address: getData.state.companyInfo.address,
    ignoreVehicleTypes: getData.state.companyInfo.ignoreVehicleTypes,
  })

  const handleNumber = (e) => {
    setPhoneNumber(e)
  };

  const handlData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handlSubmit = () => {
    let msg = null;
    if (formData.name.length === 0) {
      msg = "Please input company name!";
    } else if (formData.owner.length === 0) {
      msg = "Please input owner!";
    } else if (formData.email.length === 0) {
      msg = "Please input company email!";
    } else if (phoneNumber.length === 0) {
      msg = "Please input mobile number!";
    } else if (formData.country.length === 0) {
      msg = "Please input country";
    } else if (formData.address.length === 0) {
      msg = "Please input address!";
    }

    if (msg != null) {
      notification.warning({
        description: msg,
      });
      return;
    }
    final()
  }

  const final = async () => {
    const sendData = {
      name: formData.name,
      owner: formData.owner,
      email: formData.email,
      mobile: phoneNumber,
      country: formData.country,
      address: formData.address,
      ignoreVehicleTypes: formData.ignoreVehicleTypes,
      logo: logoPath,
    }
    const res = await updateCompany(getData.state.companyInfo?._id, sendData);
    if (res?.status == 200) {
      notification.success({
        description: "Company has been updated successfully!",
      });
      setTimeout(() => {
        navigate("/Company");
      }, 1000);
    }
    else {
      notification.error({
        description: res?.data.message,
      });
    }
  }

  const onChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const result = await uploadAvatar(selectedFile);
      if (result.status == 200) {
        setLogoPath(result.data.filePath);
      }
    }
  }

  return (
    <div className="add-company-main w-100 h-100">
      <p>Edit Company</p>
      <div className="add-company-div1 d-flex justify-content-between flex-column p-5 mx-5">
        <div className="d-flex justify-content-center flex-column">

          <div className='person-add-company-sub1-add-company-div1 d-flex justify-content-center position-relative mb-5'>
            <input
              type='file'
              className='personfile position-absolute'
              accept='image/*'
              onChange={onChange}
            />
            <img
              crossOrigin='*'
              src={logoPath ? process.env.REACT_APP_URL + '/' + logoPath : '/assets/common_company.png'}
              alt="Company Logo"
              className="person-add-company"
            />
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-5'>
            <div className='d-flex flex-column'>
              <label>Company Name</label>
              <input className='normal-input' value={formData.name} type='text' name="name" onChange={handlData} />
            </div>
            <div className='sub2-subsub1-sub1-add-company-div1 d-flex flex-column position-relative'>
              <label>Owner Name</label>
              <input className='normal-input' value={formData.owner} type='text' name="owner" onChange={handlData} />
            </div>
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-5'>
            <div className='d-flex flex-column'>
              <label>Email</label>
              <input className='normal-input' value={formData.email} type='email' name="email" onChange={handlData} />
            </div>
            <div className='d-flex flex-column'>
              <label>Mobile No.</label>
              <div className='normal-input border-0 p-0'>
                <PhoneNumberInput
                  value={phoneNumber}
                  onChange={handleNumber}
                />
              </div>
            </div>
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-5'>
            <div className='d-flex flex-column position-relative'>
              <label>Country & City</label>
              <div className="normal-input p-0 border-0">
                <Select
                  showSearch
                  allowClear
                  size={isMobile ? "small" : "large"}
                  className="w-100"
                  placeholder="City & Country"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={CountryData?.map((item) => ({
                    label: `${item.city}, ${item.country}`, value: `${item.city}, ${item.country}`
                  }))}
                  value={formData.country}
                  onChange={(value) => setFormData({ ...formData, "country": value })}
                />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <label>Address</label>
              <input className='normal-input' type='text' value={formData.address} name="address" onChange={handlData} />
            </div>
          </div>
          <div className='add-input-container d-flex justify-content-evenly mb-4 pt-4'>
            <div className='d-flex flex-column position-relative'>
              <div className="fw-semibold normal-input d-flex flex-column gap-4" style={{ background: 'white', height: "auto", border: 0, padding: "1.8rem" }}>
                <div className="text-danger" style={{ textDecoration: "underline" }}>
                  Assign Device Type
                </div>
                {VehicleTypes.map((item, index) =>
                  <Checkbox
                    key={index}
                    className='ms-3'
                    checked={!isIgnoreVehicleType(formData.ignoreVehicleTypes, item.type)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFormData({ ...formData, ignoreVehicleTypes: formData.ignoreVehicleTypes.filter(type => type != item.type) });
                      } else {
                        setFormData({ ...formData, ignoreVehicleTypes: [...formData.ignoreVehicleTypes, item.type] });
                      }
                    }}
                  >
                    <span className='ms-4'>{item.name}</span>
                  </Checkbox>
                )}
              </div>
            </div>
            <div className='d-flex flex-column position-relative'>
              <div className="normal-input" style={{ visibility: "hidden" }}></div>
            </div>
          </div>
        </div>
        <div className="subsub5-sub1-add-user-div1 d-flex justify-content-center">
          <button className='me-5' onClick={() => navigate("/Company")}>Cancel</button>
          <button onClick={() => handlSubmit()}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default EditCompany;
