import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelAutoConnect = ({token, deviceImei, commandResponse}) => {
  
  const [connectionMode, setConnectionMode] = useState("")
  const [externalMac, setExternalMac] = useState("")
  const [externalName, setExternalName] = useState("")
  const [externalPin, setExternalPin] = useState("")
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendAutoConnectToExternalDevice = async () => {
    return sendCommonRequest(cmdType.AutoConnectToExternalDevice, {
      mode: connectionMode,
      mac: externalMac,
      name: externalName,
      pin: externalPin
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.AutoConnectToExternalDevice,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setConnectionMode(response.mode);
    setExternalMac(response.mac);
    setExternalName(response.name);
    setExternalPin(response.pin);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.AutoConnectToExternalDevice) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Auto connect to external device"
      date={updatedDate}
      onSendAction={sendAutoConnectToExternalDevice}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Connection Mode"
            value={connectionMode}
            onChange={(value) => setConnectionMode(value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "None" },
              { value: "1", label: "Handsfree" },
              { value: "2", label: "OBDII" },
              { value: "3", label: "Data Link" },
              { value: "4", label: "Inateck Scanner" },
              { value: "5", label: "User ID" },
            ]}
          />
          <TextInput
            name="External MAC"
            type="text"
            value={externalMac}
            onChange={(value) => setExternalMac(value)} />
          <TextInput
            name="External Name"
            type="text"
            value={externalName}
            onChange={(value) => setExternalName(value)} />
          <TextInput
            name="External PIN"
            type="number"
            value={externalPin}
            onChange={(value) => setExternalPin(value)} />
        </div>
      )} />
  );
};

export default PanelAutoConnect;
