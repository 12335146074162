import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelAccelerometer = ({token, deviceImei, commandResponse}) => {
  
  const [filter, setFilter] = useState(false)
  const [mode, setMode] = useState("");
  const [updatedDate, setUpdatedDate] = useState("")

  const sendAccelerator = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.AccelerometerCalibrationAndGravityFilter,
      params: {
        mode: mode,
        filter: filter ? "1" : "0"
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.AccelerometerCalibrationAndGravityFilter,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setMode(response.mode);
    setFilter(parseInt(response.filter) === 1);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.AccelerometerCalibrationAndGravityFilter) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Accelerometer calibration and gravity filter"
      description="Object motion detection is to be configured to determine device working mode. Other functionalities that depend on movement source are: power manager, fuel consumption and trip."
      date={updatedDate}
      onSendAction={sendAccelerator}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Sleep Mode"
            value={mode}
            onChange={(value) => setMode(value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Disabled" },
              { value: "1", label: "Once" },
              { value: "2", label: "Continuous" },
            ]}
          />

          <div className="input-element border-0 d-flex justify-content-start mb-3">
            <Switch
              value={filter}
              onChange={(checked) => setFilter(checked)}
              suffix={"Enable Gravity Filter"}
            />
          </div>

        </div>
      )} />
  );
};

export default PanelAccelerometer;
