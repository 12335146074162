import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import "./Company.css";
import { activateCompany, deleteCompany, getCompanies } from "../../services/axios";
import { showConfirmEx } from "../../utils/globals";
import { notification, Select } from "antd";
import AssignVehicleCompany from "./assignVehicle/AssignVehicleCompany";

const Company = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [stateColor, setStateColor] = useState("#7A7D8B");
  const [searchCompanyNameText, setSearchCompanyNameText] = useState("");
  const [searchOwnerText, setSearchOwnerText] = useState("");
  const [searchCountryText, setSearchCountryText] = useState(null);
  const [searchEmailText, setSearchEmailText] = useState("");
  const [searchStatusText, setSearchStatusText] = useState(null);
  const [searchFold, setSearchFold] = useState(true);

  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    getCompanies()
      .then(res => {
        if (res.status == 200) {
          setCompanies(res.data.result);

          let temp = [];
          for (const item of res.data.result) {
            if (!temp.includes(item.country)) {
              temp.push(item.country);
            }
          }
          temp.sort();
          setCities(temp);
        }
      })
      .catch(err => { });

  }, []);

  useEffect(() => {
    setFilteredCompanies(
      companies.filter((item) => {
        return (
          (!searchCompanyNameText || item.name.toLowerCase().includes(searchCompanyNameText.toLowerCase())) &&
          (!searchOwnerText || item.owner.toLowerCase().includes(searchOwnerText.toLowerCase())) &&
          (!searchEmailText || item.email.toLowerCase().includes(searchEmailText.toLowerCase())) &&
          (!searchCountryText || item.country.toLowerCase().includes(searchCountryText.toLowerCase())) &&
          (!searchStatusText ||
            (searchStatusText === "Active" && item.status) || (searchStatusText === "Deactivated" && !item.status))
        );
      })
    );
  }, [
    companies,
    searchCompanyNameText,
    searchOwnerText,
    searchEmailText,
    searchStatusText,
    searchCountryText,
  ]);

  const handleStateColor = (value) => {
    setSearchStatusText(value);
  };

  const handleCompanyName = (event) => {
    const val = event.target.value;
    setSearchCompanyNameText(val);
  };

  const handleOwner = (event) => {
    const val = event.target.value;
    setSearchOwnerText(val);
  };

  const handleEmail = (event) => {
    const val = event.target.value;
    setSearchEmailText(val);
  };

  const handleCountry = (value) => {
    setSearchCountryText(value);
  };

  const handleViewCompany = (item) => {
    navigate("/Company/ViewCompany", {
      state: {
        companyInfo: item
      },
    });
  };

  const handleEditCompany = (item) => {
    navigate("/Company/EditCompany", {
      state: {
        companyInfo: item
      },
    });
  };

  const handleRemoveCompany = (id, name) => {
    showConfirmEx(`Are You sure to Delete the company ${name}?`)
      .then(async () => {
        var res = await deleteCompany(id);
        if (res?.status === 200) {
          notification.success({
            description: "Company has been removed successfully!",
          });

          var ret = await getCompanies();
          setCompanies(ret?.data?.result);
        } else {
          notification.error({
            description: "Something went wrong!",
          });
        }
      })
      .catch(() => {
      });
  };

  const handleUpdateStatus = (status, id, name) => {
    showConfirmEx(`Are you sure to ${status ? 'Deactivate' : 'Activate'} company ${name}?`)
      .then(async () => {
        const sendData = {
          id: id,
          status: !status,
        };
        let res = await activateCompany(sendData);
        if (res.status === 200) {
          var ret = await getCompanies();
          setCompanies(ret?.data?.result);
        } else if (res.data.message) {
          notification.error({
            description: res.data.message,
          });
        }
      })
      .catch(() => { });
  };

  const handleAssignVehicle = (item) => {
    setSelectedCompany(item);
    setIsShowModal(true);
  }

  const handleClear = () => {
    setSearchCompanyNameText("");
    setSearchOwnerText("");
    setSearchEmailText("");
    setSearchCountryText("");
    setSearchStatusText("");
    setStateColor("grey");
  };

  return (
    <div className="company-main">
      {!isMobile ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="sub1-div1 d-flex justify-content-between align-items-center w-100">
            <p className="px-5 text-white d-flex justify-content-center align-items-center">
              Total Companies <span className="ms-3">{filteredCompanies?.length}</span>
            </p>
            <div className="d-flex position-relative">
              <CSVLink
                data={filteredCompanies}
                filename="companys"
                style={{ textDecoration: "none" }}
              >
                <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                  <img src="/assets/export.svg" alt="none" />
                  <button>Export</button>
                </div>
              </CSVLink>
              <div className="tab-button d-flex justify-content-center align-items-center mx-1" style={{ width: "17rem" }}>
                <img src="/assets/addbtn.svg" alt="none" />
                <button onClick={() => navigate("/Company/AddCompany")}>
                  Add Company
                </button>
              </div>
            </div>
          </div>
          <div className="sub2-div1 d-flex flex-column justify-content-end align-items-end w-100">
            <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 w-100">
              <div className="sub1-subsub1-sub2-div1 d-flex align-items-center my-2">
                <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
                <span>Search company</span>
              </div>
              <div className="sub2-subsub1-sub2-div1 d-flex justify-content-between align-items-center">
                <input
                  className="field-input"
                  type="text"
                  placeholder="Company Name"
                  value={searchCompanyNameText}
                  onChange={handleCompanyName}
                />
                <input
                  className="field-input"
                  type="text"
                  placeholder="Owner"
                  value={searchOwnerText}
                  onChange={handleOwner}
                />
                <input
                  className="field-input"
                  type="mail"
                  placeholder="Email"
                  value={searchEmailText}
                  onChange={handleEmail}
                />
                <Select
                  allowClear
                  className="field-select"
                  placeholder="City & Country"
                  options={cities.map((item, index) => ({
                    label: item,
                    value: item
                  }))}
                  value={searchCountryText}
                  onChange={handleCountry}
                />
                <Select
                  allowClear
                  className="field-select"
                  placeholder="Status"
                  options={[
                    { label: "Active", value: 1 },
                    { label: "Deactivated", value: 0 },
                  ]}
                  value={searchStatusText}
                  onChange={handleStateColor}
                />
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="subsub1-sub1-company-div2 py-3">
                <p className="mb-0 text-center"></p>
                <p className="mb-0 text-start">Company Name</p>
                <p className="mb-0 text-start">Owner</p>
                <p className="mb-0 text-start">Email</p>
                <p className="mb-0 text-start">Mobile No.</p>
                <p className="mb-0 text-start">Vehicles</p>
                <p className="mb-0 text-start">City & Country</p>
                <p className="mb-0 text-start">Status</p>
                <p className="mb-0 text-start"></p>
              </div>
              <div className="sub2-div2 overflow-auto">
                {filteredCompanies.map((item, index) => {
                  return (
                    <div key={index} className="d-flex">
                      <p className="sub1-import-company">{index + 1}</p>
                      <div className="subsub1-sub2-company-div2">
                        <div className="item justify-content-center">
                          {item.logo &&
                            <img
                              crossOrigin="*"
                              src={process.env.REACT_APP_URL + "/" + item.logo}
                              alt="none"
                              style={{ maxWidth: "90%", maxHeight: "7rem" }}
                            />
                          }
                        </div>
                        <p className="item">{item.name}</p>
                        <p className="item">{item.owner}</p>
                        <p className="item">{item.email}</p>
                        <p className="item">{item.mobile}</p>
                        <p className="item">{item.vehicleCount}</p>
                        <p className="item">{item.country}</p>
                        <p className="item" style={{ color: item.status ? "#63D16E" : "#FF3062" }}>
                          {item.status ? "Activate" : "Deactivated"}
                        </p>
                        <div className="item company-dropdown position-relative justify-content-center">
                          <FontAwesomeIcon
                            className="dropdown-toggle company-dropdown-img px-3"
                            icon={faEllipsisVertical}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div className="dropdown-menu company-dropdown-div">
                            <div className="sub1-company-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleViewCompany(item)}
                              >
                                <img src="/assets/view.svg" alt="none" />
                                <p className="mb-0 ms-3">View</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleEditCompany(item)}
                              >
                                <img src="/assets/edit.svg" alt="none" />
                                <p className="mb-0 ms-3">Edit</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleRemoveCompany(item._id, item.name)}
                              >
                                <img src="/assets/remove.svg" alt="none" />
                                <p className="mb-0 ms-3">Remove</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleUpdateStatus(item.status, item._id, item.name)}
                              >
                                <img src={item.status ? "/assets/deactivate.svg" : "/assets/Activate.svg"} alt="none" />
                                <p className="mb-0 ms-3">
                                  {item.status ? "Deactivate" : "Active"}
                                </p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleAssignVehicle(item)}
                              >
                                <img src="/assets/assign_vehicle.svg" alt="none" />
                                <p className="mb-0 ms-3">Assign Vehicle</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="tab mobile-title mb-4">
            <div
              aria-current="page"
              className="px-3 d-flex justify-content-center py-2"
              style={{
                color: "white",
                backgroundColor: "#1A2678",
                borderRadius: "30px",
              }}
            >
              <div className="d-flex content align-items-center">
                <FontAwesomeIcon className="me-4" icon={faBuilding} />
                Companies
              </div>
            </div>
          </div>
          <div className="sub1-div1 d-flex justify-content-between align-items-end w-100 p-0 my-3">
            <p
              className="px-3 text-white d-flex justify-content-evenly align-items-center mb-0"
              style={{ width: "16rem" }}
            >
              Total Companies <span className="ms-3">{filteredCompanies?.length}</span>
            </p>
            <div className="d-flex position-relative">
              <CSVLink
                data={filteredCompanies}
                filename="companys"
                style={{ textDecoration: "none" }}
              >
                <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                  <img src="/assets/export.svg" alt="none" />
                  <button>Export</button>
                </div>
              </CSVLink>
              <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                <img src="/assets/addbtn.svg" alt="none" />
                <button className="ms-0" onClick={() => navigate("/Company/AddCompany")}>
                  Add Company
                </button>
              </div>
            </div>
          </div>
          <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 px-4 w-100">
            <div className="sub1-subsub1-sub2-div1 d-flex align-items-center p-2" onClick={() => setSearchFold(!searchFold)}>
              <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
              <span>Search company</span>
              <img
                className="ms-auto accordion"
                style={!searchFold ? { transform: "rotate(180deg)" } : { transform: "none" }}
                src="/assets/arrow-down.png"
                alt="Arrow Down"
              />
            </div>
            {!searchFold && (
              <div className="sub2-subsub1-sub2-div1 d-flex flex-column px-0">
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="text"
                      placeholder="Company Name"
                      value={searchCompanyNameText}
                      onChange={handleCompanyName}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="text"
                      placeholder="Owner"
                      value={searchOwnerText}
                      onChange={handleOwner}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="mail"
                      placeholder="Email"
                      value={searchEmailText}
                      onChange={handleEmail}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-select"
                      placeholder="City & Country"
                      options={cities.map((item, index) => ({
                        label: item,
                        value: item
                      }))}
                      value={searchCountryText}
                      onChange={handleCountry}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-select"
                      placeholder="Status"
                      options={[
                        { label: "Active", value: 1 },
                        { label: "Deactivated", value: 0 },
                      ]}
                      value={searchStatusText}
                      onChange={handleStateColor}
                    />
                  </div>
                </div>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-auto py-1"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            )}
          </div>
          <div className={`sub2-div2 d-flex flex-column px-4 w-100 overflow-auto ms-0 ${searchFold && "folded"}`}>
            {filteredCompanies?.map((item, index) => {
              return (
                <div className="import-company" key={index}>
                  <p className="sub1-import-company">{index + 1}</p>
                  <div className="subsub1-sub2-company-div2 d-flex flex-column align-items-center py-2 px-3">
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Company Name</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.name}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Owner</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.owner}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Email</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.email}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Mobile No.</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.mobile}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">
                        Vehicles
                      </p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.vehicleCount}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">City & Country</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.country}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Status</p>
                      <div className="mb-0 px-2 col-8 item justify-content-between">
                        <p
                          className="item mb-0 justify-content-start"
                          style={{ color: item.status ? "#63D16E" : "#FF3062" }}
                        >
                          {item.status ? "Active" : "Deactivated"}
                        </p>
                        <div className="company-dropdown d-flex position-relative justify-content-end">
                          <FontAwesomeIcon
                            className="dropdown-toggle company-dropdown-img px-3"
                            icon={faEllipsisVertical}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div className="dropdown-menu company-dropdown-div">
                            <div className="sub1-company-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleViewCompany(item)}
                              >
                                <img src="/assets/view.svg" alt="none" />
                                <p className="mb-0 ms-3">View</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleEditCompany(item)}
                              >
                                <img src="/assets/edit.svg" alt="none" />
                                <p className="mb-0 ms-3">Edit</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleRemoveCompany(item._id, item.name)}
                              >
                                <img src="/assets/remove.svg" alt="none" />
                                <p className="mb-0 ms-3">Remove</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleUpdateStatus(item.status, item._id, item.name)}
                              >
                                <img src={item.status ? "/assets/deactivate.svg" : "/assets/Activate.svg"} alt="none" />
                                <p className="mb-0 ms-3">
                                  {item.status ? "Deactivate" : "Active"}
                                </p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleAssignVehicle(item)}
                              >
                                <img src="/assets/assign_vehicle.svg" alt="none" />
                                <p className="mb-0 ms-3">Assign Vehicle</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <AssignVehicleCompany
        isShowModal={isShowModal}
        company={selectedCompany}
        onClose={() => setIsShowModal(false)}
      />

    </div>
  );
};

export default Company;
