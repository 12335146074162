import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { Checkbox, Input, message, notification, Select } from "antd";
import { deleteTickets, getCompanies, getTicketList, updateTicketStatus } from "../../services/axios";
import { formatDateTime, showConfirmEx } from "../../utils/globals";

import "./Ticket.css";
import NewTicket from "./newTicket/NewTicket";
import ViewTicket from "./viewTicket/ViewTicket";
import CustomDatePicker from "../commons/datepicker/CustomDatePicker";

const Ticket = () => {

  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const [dataList, setDataList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  const [companies, setCompanies] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchRefText, setSearchRefText] = useState("");
  const [searchSubjectText, setSearchSubjectText] = useState("");
  const [searchStatus, setSearchStatus] = useState(null);
  const [searchCompanyText, setSearchCompanyText] = useState(null);
  const [searchFold, setSearchFold] = useState(true);

  const [isShowNewModal, setIsShowNewModal] = useState(false);
  const [isShowViewModal, setIsShowViewModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const getList = async (page = 1) => {
    setIsLoading(true);
    const res = await getTicketList({
      pageNum: page,
      pageSize: pageSize,
      fromDate: startDate,
      toDate: endDate,
      referenceNo: searchRefText,
      subject: searchSubjectText,
      status: searchStatus,
      company: searchCompanyText,
    });
    if (res?.status == 200) {
      if (page === 1) {
        setDataList(res?.data?.result);
      } else {
        setDataList([
          ...dataList,
          ...res?.data?.result
        ]);
      }
      setTotalCount(res?.data?.totalCount);
      setPendingCount(res?.data?.pendingCount);
      setPageNum(page);
    } else {
      setDataList([]);
      setTotalCount(0);
      setPendingCount(0);
      setPageNum(1);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getCompanies()
      .then(res => {
        if (res.status == 200) {
          setCompanies(res.data.result);
        }
      })
      .catch(err => { });
  }, []);

  useEffect(() => {
    getList(1);
  }, [searchRefText, searchSubjectText, searchStatus, searchCompanyText, startDate, endDate]);

  useEffect(() => {
    if (isLoading) {
      messageApi.open({
        type: 'loading',
        content: 'Loading Data ..',
        duration: 0,
      });
    } else {
      messageApi.destroy();
    }
  }, [isLoading]);

  const scrollRef = useBottomScrollListener(async () => {
    const totalPage = Math.ceil(totalCount / pageSize);
    if (totalPage > pageNum) {
      await getList(pageNum + 1);
    }
  });

  useEffect(() => {
    let allChecked = true;
    for (const ticket of dataList) {
      if (!checkedList?.find(id => id == ticket._id)) {
        allChecked = false;
        break;
      }
    }
    setCheckedAll(allChecked);
  }, [checkedList, dataList]);

  const handleCheck = (checked, item) => {
    const checkedTickets = [...checkedList];
    const index = checkedTickets.findIndex(id => id == item._id);
    if (index != -1) {
      checkedTickets.splice(index, 1);
    }
    if (checked) {
      checkedTickets.push(item._id);
    }
    setCheckedList(checkedTickets);
  }

  const handleCheckAll = (checked) => {
    if (checked) {
      setCheckedList(dataList?.map(item => item._id));
    } else {
      setCheckedList([]);
    }
    setCheckedAll(checked);
  }

  const handleRef = (event) => {
    const val = event.target.value;
    setSearchRefText(val);
  };

  const handleSubject = (event) => {
    const val = event.target.value;
    setSearchSubjectText(val);
  };

  const handleStatus = (val) => {
    setSearchStatus(val);
  };

  const handleCompany = (val) => {
    setSearchCompanyText(val);
  };

  const handleClear = () => {
    setSearchRefText("");
    setSearchSubjectText("");
    setSearchStatus(null);
    setSearchCompanyText(null);
    setStartDate(null);
    setEndDate(null);
  };

  const handleUpdateStatus = (status, id) => {
    showConfirmEx(`Are you sure to change status to ${status}?`)
      .then(async () => {
        const sendData = {
          id, status
        };
        let res = await updateTicketStatus(sendData);
        if (res.status === 200) {
          setDataList(dataList.map(item => {
            return {
              ...item,
              status: (item._id == id) ? status : item.status
            };
          }));
          if (status == "Pending") {
            setPendingCount(pendingCount + 1);
          } else {
            setPendingCount(pendingCount - 1);
          }
        } else if (res.data.message) {
          notification.error({
            description: res.data.message,
          });
        }
      })
      .catch(() => {
      });
  };

  const handleViewTicket = (item) => {
    setSelectedTicket(item);
    setIsShowViewModal(true);
  }

  const handleRemoveTicket = (ids) => {
    showConfirmEx(`Are You sure to Delete the ticket?`)
      .then(async () => {
        var res = await deleteTickets(ids);
        if (res?.status === 200) {
          // calc counts info
          let pcnt = 0;
          for (const id of ids) {
            let item = dataList.find(item => item._id == id);
            if (item?.status == "Pending") {
              pcnt++;
            }
          }
          setPendingCount(pendingCount - pcnt);
          setTotalCount(totalCount - ids.length);

          notification.success({
            description: "Tickets has been removed successfully!",
          });
          setDataList(dataList.filter(item => !ids.includes(item._id)));
        } else {
          notification.error({
            description: "Something went wrong!",
          });
        }
      })
      .catch(() => {
      });
  };

  const handleCreateSuccess = (newTicket) => {
    setIsShowNewModal(false);
    setDataList([
      newTicket,
      ...dataList
    ]);
    setTotalCount(totalCount + 1);
    setPendingCount(pendingCount + 1);
  }
  const handleReplySuccess = (updateTicket) => {
    setDataList(dataList.map(item => {
      if (item._id == updateTicket._id) {
        return updateTicket;
      } else {
        return item;
      }
    }));
    setIsShowViewModal(false);
  }
  const handleCloseTicket = (ticketId) => {
    setDataList(dataList.map(item => {
      return {
        ...item,
        status: (item._id == ticketId) ? "Resolved" : item.status
      };
    }));
    setIsShowViewModal(false);
    setPendingCount(pendingCount - 1);
  }

  return (
    <div className="ticket-main">
      {contextHolder}

      {!isMobile ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="sub1-div1 d-flex justify-content-between align-items-center w-100">
            <div className="all-ticket-div1 h-100 d-flex justify-content-between">
              <p
                onClick={() => setSearchStatus("")}
                className="px-5 text-white d-flex justify-content-center align-items-center cursor-pointer"
              >
                Total Tickets <span className="ms-3">{totalCount}</span>
              </p>
              <p
                onClick={() => setSearchStatus("Pending")}
                className="px-5 text-white d-flex justify-content-center align-items-center cursor-pointer"
              >
                Pending <span className="ms-3">{pendingCount}</span>
              </p>
              <p
                onClick={() => setSearchStatus("Resolved")}
                className="px-5 text-white d-flex justify-content-center align-items-center cursor-pointer"
              >
                Resolved<span className="ms-3">{totalCount - pendingCount}</span>
              </p>
            </div>
            <div className="d-flex">
              <div className="tab-button d-flex justify-content-center align-items-center mx-1"
                onClick={() => { setIsShowNewModal(true) }}
              >
                <img src="/assets/Ticket.svg" alt="none" />
                <button className="ms-2">Create</button>
              </div>
              {checkedList.length > 0 &&
                <div className="tab-button d-flex justify-content-center align-items-center mx-1"
                  onClick={() => handleRemoveTicket(checkedList)}
                >
                  <img src="/assets/delete.svg" alt="none" />
                  <button className="ms-2">Delete</button>
                </div>
              }
            </div>
          </div>
          <div className="sub2-div1 d-flex flex-column justify-content-end align-items-end w-100">
            <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 w-100">
              <div className="sub1-subsub1-sub2-div1 d-flex align-items-center my-2">
                <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
                <span>Search ticket</span>
              </div>
              <div className="sub2-subsub1-sub2-div1 d-flex justify-content-between align-items-center">
                <Input
                  className="field-input"
                  type="text"
                  placeholder="Ref No."
                  value={searchRefText}
                  onChange={handleRef}
                />
                <Input
                  className="field-input"
                  type="text"
                  placeholder="Subject"
                  value={searchSubjectText}
                  onChange={handleSubject}
                />
                <Select
                  allowClear
                  className="field-select"
                  placeholder="Company"
                  options={companies?.map((item, index) => ({
                    label: item.name, value: item._id
                  }))}
                  value={searchCompanyText}
                  onChange={handleCompany}
                />
                <div className="field-input date-picker-input">
                  <CustomDatePicker
                    placeholderText="From"
                    selectedDate={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                    dateFormat="dd/MM/yyyy"
                    showTimeInput={false}
                  />
                  <img src="/assets/calender.png" alt="cal" />
                </div>
                <div className="field-input date-picker-input">
                  <CustomDatePicker
                    placeholderText="To"
                    selectedDate={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    showTimeInput={false}
                    maxDate={new Date()}
                    maxTime={new Date()}
                  />
                  <img src="/assets/calender.png" alt="cal" />
                </div>
                <Select
                  allowClear
                  className="field-select"
                  placeholder="Status"
                  options={[
                    { label: "Pending", value: "Pending" },
                    { label: "Resolved", value: "Resolved" },
                  ]}
                  value={searchStatus}
                  onChange={handleStatus}
                />
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="subsub1-sub1-ticket-div2 py-3">
                <div className="mb-0 d-flex justify-content-center align-items-center">
                  <Checkbox
                    checked={checkedAll}
                    onChange={(e) => handleCheckAll(e.target.checked)}
                  />
                </div>
                <p className="mb-0 text-start">Reference No.</p>
                <p className="mb-0 text-start">Subject</p>
                <p className="mb-0 text-start">Date & Time</p>
                <p className="mb-0 text-start">User</p>
                <p className="mb-0 text-start">Company</p>
                <p className="mb-0 text-start">Status</p>
                <p className="mb-0 text-start"></p>
              </div>
              <div className="sub2-div2 overflow-auto" ref={scrollRef}>
                {dataList?.map((item, index) => {
                  return (
                    <div key={index} id="import-ticket">
                      <p id="sub1-import-ticket">{index + 1}</p>
                      <div className="subsub1-sub2-ticket-div2">
                        <div className="item justify-content-center">
                          <Checkbox
                            checked={checkedList.includes(item?._id)}
                            onChange={(e) => handleCheck(e.target.checked, item)}
                          />
                        </div>
                        <p className="item">
                          <a className="text-dark cursor-pointer" onClick={() => handleViewTicket(item)}>{item.referenceNo}</a>
                        </p>
                        <p className="item">{item.subject}</p>
                        <p className="item">{formatDateTime(item.createdAt)}</p>
                        <p className="item">{item.user?.lname}</p>
                        <p className="item">{item.company?.name}</p>
                        <p className="item" style={{ color: item.status == "Resolved" ? "#63D16E" : "#FF3062" }}>
                          {item.status}
                        </p>
                        <div className="ticket-dropdown item d-flex position-relative justify-content-center">
                          <FontAwesomeIcon
                            className="dropdown-toggle ticket-dropdown-img px-3"
                            icon={faEllipsisVertical}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div className="dropdown-menu ticket-dropdown-div">
                            <div className="sub1-ticket-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                              <div className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleUpdateStatus(item.status == 'Resolved' ? 'Pending' : 'Resolved', item._id)}
                              >
                                <img src={item.status === "Resolved" ? "/assets/deactivate.svg" : "/assets/Add.svg"} alt="none" />
                                <p className="mb-0 ms-3">{item.status == 'Resolved' ? 'Reopen' : 'Resolved'}</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleRemoveTicket([item._id])}
                              >
                                <img src="/assets/remove.svg" alt="none" />
                                <p className="mb-0 ms-3">Delete</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="tab mobile-title mb-4">
            <div
              aria-current="page"
              className="px-3 d-flex justify-content-center py-2"
              style={{ color: "white", backgroundColor: "#1A2678", borderRadius: "30px", }}
            >
              <div className="d-flex content align-items-center">
                <FontAwesomeIcon
                  className="me-4"
                  icon={faTriangleExclamation}
                />
                Ticket
              </div>
            </div>
          </div>
          <div className="sub1-div1 d-flex justify-content-between align-items-end w-100 p-0 my-3">
            <p className="px-3 text-white d-flex justify-content-evenly align-items-center mb-0">
              Total Tickets <span className="ms-3">{totalCount}</span>
            </p>
            <div className="d-flex">
              <div
                className="tab-button d-flex justify-content-center align-items-center mx-1"
                onClick={() => { setIsShowNewModal(true) }}
              >
                <img src="/assets/Ticket.svg" alt="none" />
                <button className="ms-2">Create</button>
              </div>
              {checkedList.length > 0 &&
                <div
                  className="tab-button d-flex justify-content-center align-items-center mx-1"
                  onClick={() => handleRemoveTicket(checkedList)}
                >
                  <img src="/assets/delete.svg" alt="none" />
                  <button className="ms-2">Delete</button>
                </div>
              }
            </div>
          </div>
          <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 px-4 w-100">
            <div className="sub1-subsub1-sub2-div1 d-flex align-items-center p-2" onClick={() => setSearchFold(!searchFold)}>
              <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
              <span>Search ticket</span>
              <img
                className="ms-auto accordion"
                style={!searchFold ? { transform: "rotate(180deg)" } : { transform: "none" }}
                src="/assets/arrow-down.png"
                alt="Arrow Down"
              />
            </div>
            {!searchFold && (
              <div className="sub2-subsub1-sub2-div1 d-flex flex-column px-0">
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <Input
                      className="field-input"
                      type="text"
                      placeholder="Ref No."
                      value={searchRefText}
                      onChange={handleRef}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <Input
                      className="field-input"
                      type="text"
                      placeholder="Subject"
                      value={searchSubjectText}
                      onChange={handleSubject}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-select"
                      placeholder="Status"
                      options={[
                        { label: "Pending", value: "Pending" },
                        { label: "Resolved", value: "Resolved" },
                      ]}
                      value={searchStatus}
                      onChange={handleStatus}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-select"
                      placeholder="Company"
                      options={companies?.map((item, index) => ({
                        label: item.name, value: item._id
                      }))}
                      value={searchCompanyText}
                      onChange={handleCompany}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <div className="field-input date-picker-input">
                      <CustomDatePicker
                        placeholderText="From"
                        selectedDate={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        showTimeInput={false}
                      />
                      <img src="/assets/calender.png" alt="cal" />
                    </div>
                  </div>
                  <div className="col-6 px-1">
                    <div className="field-input date-picker-input">
                      <CustomDatePicker
                        placeholderText="To"
                        selectedDate={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat="dd/MM/yyyy"
                        showTimeInput={false}
                        maxDate={new Date()}
                        maxTime={new Date()}
                      />
                      <img src="/assets/calender.png" alt="cal" />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-auto py-1"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            )}
          </div>
          <div className={`sub2-div2 d-flex flex-column px-4 w-100 overflow-auto ms-0 ${searchFold && "folded"}`} ref={scrollRef}>
            {dataList?.map((item, index) => {
              return (
                <div id="import-ticket" key={index} onClick={() => handleViewTicket(item)}>
                  <p id="sub1-import-ticket">{index + 1}</p>
                  <div className="subsub1-sub2-ticket-div2 d-flex flex-column align-items-center py-2 px-3">
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">
                        Reference No.
                      </p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.referenceNo}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Subject</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.subject}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Date & Time</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {formatDateTime(item?.createdAt)}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">User</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.user?.lname}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Company</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.company?.name}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Status</p>
                      <div className="mb-0 px-2 col-8 item justify-content-between">
                        <p
                          className="item mb-0 justify-content-start"
                          style={{ color: item.status == "Resolved" ? "#63D16E" : "#FF3062" }}
                        >
                          {item.status}
                        </p>
                        <div className="ticket-dropdown d-flex position-relative justify-content-end">
                          <FontAwesomeIcon
                            className="dropdown-toggle ticket-dropdown-img px-3"
                            icon={faEllipsisVertical}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={(e) => { e.stopPropagation() }}
                          />
                          <div className="dropdown-menu ticket-dropdown-div">
                            <div className="sub1-ticket-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                              <div className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleUpdateStatus(item.status == 'Resolved' ? 'Pending' : 'Resolved', item._id)}
                              >
                                <img src={item.status === "Resolved" ? "/assets/deactivate.svg" : "/assets/Add.svg"} alt="none" />
                                <p className="mb-0 ms-3">{item.status == 'Resolved' ? 'Reopen' : 'Resolved'}</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleRemoveTicket([item._id])}
                              >
                                <img src="/assets/remove.svg" alt="none" />
                                <p className="mb-0 ms-3">Delete</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <NewTicket
        isShowModal={isShowNewModal}
        onClose={() => setIsShowNewModal(false)}
        onSuccess={handleCreateSuccess}
        companies={companies}
      />

      <ViewTicket
        isShowModal={isShowViewModal}
        ticket={selectedTicket}
        onClose={() => setIsShowViewModal(false)}
        onSuccess={handleReplySuccess}
        onCloseTicket={handleCloseTicket}
      />
    </div>
  );
};

export default Ticket;
