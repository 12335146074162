import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelBluetoothWorkMode = ({token, deviceImei, commandResponse}) => {
  
  const [bluetoothScenario, setBluetoothScenario] = useState("")
  const [localName, setLocalName] = useState("")
  const [bluetoothPairing, setBluetoothPairing] = useState("")
  const [securityMode, setSecurityMode] = useState("")
  
  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendBluetoothWorkMode = async () => {
    return sendCommonRequest(cmdType.BluetoothWorkMode, {
      setting: bluetoothScenario,
      lname: localName,
      pin: bluetoothPairing,
      mode: securityMode
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.BluetoothWorkMode,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setBluetoothScenario(response.setting);
    setLocalName(response.lname);
    setBluetoothPairing(response.pin);
    setSecurityMode(response.mode);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.BluetoothWorkMode) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Bluetooth work mode"
      date={updatedDate}
      onSendAction={sendBluetoothWorkMode}
      onRefreshAction={refreshAction}
      defaultOpen={true}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Bluetooth Scenario Setting"
            value={bluetoothScenario}
            onChange={(value) => setBluetoothScenario(value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Disable" },
              { value: "1", label: "Enable Hidden" },
              { value: "2", label: "Enable Visible" },
            ]}
          />
          <TextInput
            name="Local Name"
            type="text"
            value={localName}
            onChange={(value) => setLocalName(value)} />
          <TextInput
            name="Bluetooth Pairing PIN Code"
            type="number"
            value={bluetoothPairing}
            onChange={(value) => setBluetoothPairing(value)} />
          <SelectBox
            name="Security Mode"
            value={securityMode}
            onChange={(value) => setSecurityMode(value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "PIN Only" },
              { value: "1", label: "PIN and MAC" },
              { value: "2", label: "MAC Only" },
              { value: "3", label: "None" },
            ]}
          />
        </div>
      )} />
  );
};

export default PanelBluetoothWorkMode;
