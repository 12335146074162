import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MaskedInput from "antd-mask-input";

import { updateDevice } from "../../../services/axios";

import { Select, notification } from "antd";
import { getDevices } from "../../../redux/actions/devices";
import { DeviceType } from "../../../common/common";
import { DeviceModels } from "../../../utils/mockup";

const EditDeviceAC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [deviceModels, setDeviceModels] = useState([]);
  const [formData, setFormData] = useState({
    vehicleType: "ACS",
    plate: location.state.plate,
    imei: location.state.imei,
    deviceType: location.state.device,
    model: location.state.model,
    company: location.state.company?._id,
  });

  useEffect(async () => {
    setDeviceModels(DeviceModels);
    handleType(DeviceType.Biosense);
  }, []);

  const handleType = (deviceType) => {
    let models = [];
    if (deviceType == "") {
      models = DeviceModels;
    } else {
      models = DeviceModels.filter(item => item.type == deviceType);
    }
    setDeviceModels(models);
    let model = formData.model;
    if (deviceType != "" && model != "") {
      let device = DeviceModels.find(item => item.device == model);
      if (device.type != deviceType) {
        model = "";
      }
    }

    setFormData({ ...formData, deviceType: deviceType, model: model });
  };

  const handleData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleModelSelect = (model) => {
    let deviceType = formData.deviceType;
    let device = DeviceModels.find(item => item.device == model);
    if (device && device.type != deviceType) {
      deviceType = device.type;
      handleType(device.type);
    }
    setFormData({ ...formData, deviceType: deviceType, model: model });
  };

  const handleSubmit = () => {
    let msg = null;
    if (formData.vehicleType.length === 0) {
      msg = "Please select Vehicle type!";
    } else if (formData.plate.length === 0) {
      msg = "Please input Plate No!";
    } else if (formData.imei.includes("_") || formData.imei.length == 0) {
      msg = "Mac Address is Invalid!";
    } else if (formData.deviceType.length === 0) {
      msg = "Please select Device type!";
    } else if (formData.model.length === 0) {
      msg = "Please select Device model!";
    }
    if (msg != null) {
      notification.warning({
        description: msg,
      });
      return;
    }
    final();
  };

  const final = async () => {
    const sendData = {
      vehicleType: formData.vehicleType,
      vehicleNo: formData.plate,
      deviceImei: formData.imei,
      mobileNo: "",
      deviceType: formData.deviceType,
      deviceModel: formData.model,
      company: formData.company,
    };
    var res = await updateDevice(sendData);
    if (res?.status == 200) {
      notification.success({
        description: "Device updated successfully!",
      });
      dispatch(getDevices(false));
      setTimeout(() => {
        navigate("/Devices");
      }, 1000);
    }
  };

  return (
    <div className="add-device-main w-100 h-100">
      <p>Edit Device</p>
      <div className="add-device-div1 d-flex flex-column align-items-center p-5 mx-5">
        <div className="right-subsub1-sub1-add-device-div1 d-flex align-items-end p-5 gap-3 mb-5">
          <div className="d-flex flex-column gap-4">
            <div className="add-input-container d-flex flex-column position-relative">
              <label>Item Type</label>
              <input
                className="normal-input"
                value="Access Controller"
                type="text"
                onChange={() => { }}
                readOnly
              />
              <img
                className="vehicle-icon position-absolute"
                src="/assets/ACS.svg"
                alt="none"
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Device Name</label>
              <input
                className="normal-input"
                value={formData.plate}
                name="plate"
                type="text"
                maxLength={13}
                onChange={handleData}
              />
            </div>

            <div className="add-input-container d-flex flex-column">
              <label>Add to Company (Optional)</label>
              <Select
                allowClear
                placeholder="Select Company"
                options={
                  location?.state?.companies?.map(item => ({ label: item.name, value: item._id }))
                }
                value={formData.company}
                onChange={value => setFormData({ ...formData, company: value })}
              />
            </div>

            <div className="add-input-container d-flex flex-column">
              <p className="p-0">Device Details</p>
              <label>Device MAC</label>
              <MaskedInput
                className="normal-input"
                // mask={'##:##:##:##:##:##'}
                mask={'############'}
                size="large"
                definitions={{ '#': /[0-9a-zA-Z]/ }}
                name="imei"
                value={formData.imei}
                onChange={e => {
                  setFormData((prev) => ({ ...prev, "imei": e.target.value }));
                }}
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Device Type</label>
              <Select
                allowClear
                placeholder="Select Device Type"
                options={[
                  { label: DeviceType.Biosense, value: DeviceType.Biosense },
                ]}
                value={formData.deviceType}
                onChange={handleType}
              />
            </div>

            <div className="add-input-container d-flex flex-column">
              <label>Device Model</label>
              <Select
                showSearch
                placeholder="Select Model"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={[{ label: "Select Device Model", value: "" }].concat(deviceModels.map((item) => ({
                  label: item.device, value: item.device
                })))}
                value={formData.model}
                onChange={(value) => handleModelSelect(value)}
              />
            </div>
          </div>
          <div className="mt-5 text-center" style={{ width: "25rem" }}>
            <img
              className="" style={{ maxWidth: "100%", maxHeight: "300px" }}
              src={
                DeviceModels.filter((item) => item.device === formData.model)[0]
                  ? DeviceModels.filter((item) => item.device === formData.model)[0].path
                  : "/assets/chip.png"
              }
              alt="none"
            />
          </div>
        </div>
        <div className="sub5-right-subsub1-sub1-add-device-div1 d-flex justify-content-center w-100">
          <button className='me-5' onClick={() => navigate("/Devices")}>Cancel</button>
          <button onClick={() => handleSubmit()}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default EditDeviceAC;
