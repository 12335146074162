import React, { useEffect, useState } from "react";
import { CommandOperands, CommandPriorities } from "../../../../common/common";

import SelectBox from "../../../commons/input/SelectBox";
import TextInput from "../../../commons/input/TextInput";

const SensorItem = ({ name, values, onChange }) => {

  const [isOpen, setIsOpen] = useState(false);

  const handleChangeValue = (key, val) => {
    onChange({
      ...values,
      [key]: val
    });
  }

  return (
    <div className="w-100 d-flex flex-column align-items-center py-3 px-2 sensor-item">
      <div className="w-100 d-flex align-items-center justify-content-between gap-2 mb-3 subtitle">
        <span>EYE {name}</span>
        <div className="line flex-grow-1"></div>
        <img
          className={`cursor-pointer mx-2 expand-collapse ${isOpen ? 'expanded' : ''}`}
          src="/assets/down2.svg"
          alt="none"
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>

      {isOpen &&
        <div>
          <SelectBox
            name="Priority"
            value={values?.priority ?? ''}
            onChange={(value) => handleChangeValue("priority", value)}
            options={CommandPriorities}
          />
          <SelectBox
            name="Operand"
            value={values?.operand ?? ''}
            onChange={(value) => handleChangeValue("operand", value)}
            options={CommandOperands}
          />
          <TextInput
            name="High Level"
            type="number"
            value={values?.highLevel ?? ''}
            onChange={(value) => handleChangeValue("highLevel", value)} />
          <TextInput
            name="Low Level"
            type="number"
            value={values?.lowLevel ?? ''}
            onChange={(value) => handleChangeValue("lowLevel", value)} />
          <SelectBox
            name="Event Only"
            value={values?.eventOnly ?? ''}
            onChange={(value) => handleChangeValue("eventOnly", value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Disable" },
              { value: "1", label: "Enable" },
              { value: "2", label: "Crash" },
            ]}
          />
        </div>
      }
    </div>
  );
};

export default SensorItem;
