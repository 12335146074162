import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelDataCodec = ({token, deviceImei, commandResponse}) => {
  
  const [dataCodecToUseForDataTransmission, setDataCodecToUseForDataTransmission] = useState("0")
  const [updatedDate, setUpdatedDate] = useState("")

  const sendDataCodec = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.DataCodecToUseForDataTransmission,
      params: {
        codec: dataCodecToUseForDataTransmission
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.DataCodecToUseForDataTransmission,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setDataCodecToUseForDataTransmission(response.codec);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.DataCodecToUseForDataTransmission) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Data codec to use for data transmission"
      description="Object motion detection is to be configured to determine device working mode. Other functionalities that depend on movement source are: power manager, fuel consumption and trip."
      date={updatedDate}
      onSendAction={sendDataCodec}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Data Codec"
            value={dataCodecToUseForDataTransmission}
            onChange={(value) => setDataCodecToUseForDataTransmission(value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Codec 8" },
              { value: "1", label: "Codec 8 Extended" },
            ]}
          />
        </div>
      )} />
  );
};

export default PanelDataCodec;
