import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFileCircleCheck, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { DeviceType, IOPins, RuleTypes } from "../../common/common";

import "./Rules.css";
import { activateRule, deleteRule, getRules } from "../../services/axios";
import { showConfirmEx } from "../../utils/globals";
import { notification, Select } from "antd";
import AssignVehicleRule from "./assignVehicle/AssignVehicleRule";
import CustomRuleEdit from "./customRuleEdit/CustomRuleEdit";
import BuiltinRuleEdit from "./builtinRuleEdit/BuiltinRuleEdit";
import { sendRulesUpdatedMsg } from "../../socket";
import { useSelector } from "react-redux";

const Rules = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const devices = useSelector((state) => state.devicesList.devices);

  const [searchRuleText, setSearchRuleText] = useState(null);
  const [searchDeviceText, setSearchDeviceText] = useState(null);
  const [searchIOPinText, setSearchIOPinText] = useState(null);
  const [searchStatusText, setSearchStatusText] = useState(null);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchFold, setSearchFold] = useState(true);

  const [selectedRule, setSelectedRule] = useState(null);
  const [isShowVehicleModal, setIsShowVehicleModal] = useState(false);
  const [isShowCustomEditModal, setIsShowCustomEditModal] = useState(false);
  const [isShowBuiltinEditModal, setIsShowBuiltinEditModal] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    setFilteredData(
      data.filter((item) => {
        return (
          (!searchRuleText || item.type.toLowerCase().includes(searchRuleText.toLowerCase())) &&
          (!searchDeviceText || (item.isTeltonika && searchDeviceText == DeviceType.Teltonika) || (item.isRuptela && searchDeviceText == DeviceType.Ruptela)) &&
          (!searchIOPinText || item.ioPin === searchIOPinText) &&
          (!searchStatusText || (item.isActivated && searchStatusText == "Activated") || (!item.isActivated && searchStatusText != "Activated"))
        );
      })
    );
  }, [data, searchRuleText, searchDeviceText, searchStatusText, searchIOPinText]);

  const getList = async () => {
    const res = await getRules();
    if (res?.status == 200) {
      setData(res.data.result);
    }
  }

  const handleRule = (val) => {
    setSearchRuleText(val);
  };

  const handleDevice = (val) => {
    setSearchDeviceText(val);
  };

  const handleIOPin = (val) => {
    setSearchIOPinText(val);
  };

  const handleStateColor = (val) => {
    setSearchStatusText(val);
  };

  const handleClear = () => {
    setSearchRuleText(null);
    setSearchDeviceText(null);
    setSearchIOPinText(null);
    setSearchStatusText(null);
  };

  const handleAddRule = () => {
    setSelectedRule(null);
    setIsShowCustomEditModal(true);
  }

  const handleAddVehicle = (item) => {
    setSelectedRule(item);
    setIsShowVehicleModal(true);
  }

  const handleEditRule = (item) => {
    setSelectedRule(item);
    if (item.type == RuleTypes.Custom) {
      setIsShowCustomEditModal(true);
    } else {
      setIsShowBuiltinEditModal(true);
    }
  }

  const handleRemoveRule = (item) => {
    let msg = `Are You sure to Delete the rule ${item.name}?`;
    if (item.type == RuleTypes.Geofence) {
      msg = 'Deleting Geofence Rule will unassign all vehicles from Geofence. Are you sure to proceed?';
    }
    showConfirmEx(msg)
      .then(async () => {
        var res = await deleteRule(item._id);
        if (res?.status === 200) {
          notification.success({
            description: "Rule has been removed successfully!",
          });
          sendRulesUpdatedMsg();
          getList();
        } else {
          notification.error({
            description: "Something went wrong!",
          });
        }
      })
      .catch(() => {
      });
  };

  const handleUpdateStatus = (status, id, name) => {
    showConfirmEx(`Are you sure to ${status ? 'Deactivate' : 'Activate'} rule ${name}?`)
      .then(async () => {
        const sendData = {
          id: id,
          status: !status,
        };
        let res = await activateRule(sendData);
        if (res.status === 200) {
          sendRulesUpdatedMsg();
          getList();
        } else if (res.data.message) {
          notification.error({
            description: res.data.message,
          });
        }
      })
      .catch(() => {
      });
  };

  return (
    <div className="rules-main w-100 h-100">
      {!isMobile ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="sub1-div1 d-flex justify-content-between align-items-center w-100">
            <p className="px-5 text-white d-flex justify-content-center align-items-center">
              Total Rules <span className="ms-3">{filteredData?.length}</span>
            </p>
            <div className="d-flex">
              <div
                className="tab-button d-flex justify-content-center align-items-center mx-1"
                onClick={handleAddRule}
              >
                <img src="/assets/addbtn.svg" alt="none" />
                <button className="ms-2">Add Rule</button>
              </div>
            </div>
          </div>
          <div className="sub2-div1 d-flex flex-column justify-content-end align-items-end w-100">
            <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 w-100">
              <div className="sub1-subsub1-sub2-div1 d-flex align-items-center my-2">
                <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
                <span>Search rule</span>
              </div>
              <div className="sub2-subsub1-sub2-div1 d-flex justify-content-between align-items-center">
                <Select
                  allowClear
                  className="field-select"
                  placeholder="Rule Type"
                  options={Object.values(RuleTypes).map((type, index) => ({
                    label: type, value: type
                  }))}
                  value={searchRuleText}
                  onChange={handleRule}
                />
                <Select
                  allowClear
                  className="field-select"
                  placeholder="Device Type"
                  options={[{ label: DeviceType.Teltonika, value: DeviceType.Teltonika }, { label: DeviceType.Ruptela, value: DeviceType.Ruptela }]}
                  value={searchDeviceText}
                  onChange={handleDevice}
                />
                <Select
                  allowClear
                  className="field-select"
                  placeholder="I/O Pin"
                  options={IOPins.map(pin => ({ label: pin, value: pin }))}
                  value={searchIOPinText}
                  onChange={handleIOPin}
                />
                <Select
                  allowClear
                  className="field-select"
                  placeholder="Status"
                  options={[{ label: "Activated", value: "Activated" }, { label: "Deactivated", value: "Deactivated" }]}
                  value={searchStatusText}
                  onChange={handleStateColor}
                />
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="subsub1-sub1-rules-div2 py-3">
                <p className="mb-0 text-start ps-5">Rule Type</p>
                <p className="mb-0 text-start">Device Type</p>
                <p className="mb-0 text-start">I/O Pin</p>
                <p className="mb-0 text-start">Pin Status</p>
                <p className="mb-0 text-start">Vehicles</p>
                <p className="mb-0 text-start">Status</p>
                <p className="mb-0 text-center"></p>
              </div>
              <div className="sub2-div2 overflow-auto">
                {filteredData.map((item, index) => {
                  return (
                    <div key={index} id="import-rules">
                      <p id="sub1-import-rules">{index + 1}</p>
                      <div className="subsub1-sub2-rules-div2">
                        <p className="item ps-5">
                          {item.name} {item.ioPin && "- " + item.ioPin}
                        </p>
                        <p className="item">
                          {item.isTeltonika ? DeviceType.Teltonika : ""}
                          {item.isTeltonika && item.isRuptela ? "/" : ""}
                          {item.isRuptela ? DeviceType.Ruptela : ""}
                        </p>
                        <p className="item">{item.ioPin}</p>
                        <p className="item">
                          {
                            (item.type == RuleTypes.Custom && item.ioPin?.startsWith("D")) ? (item.pinStatus == 1 ? "High" : item.pinStatus == 2 ? "Both" : "Low") :
                              (item.type == RuleTypes.Custom && item.ioPin?.startsWith("A")) ? (item.pinStatus == 1 ? "Greater Than " : "Less Than ") + item.threshold : ""
                          }
                        </p>
                        <p className="item">
                          {(!item.ignoreDevices || item.ignoreDevices?.length == 0) ? 'All' : (devices?.length - item.ignoreDevices?.length)}
                        </p>
                        <p className="item" style={{ color: item.isActivated ? "#63D16E" : "#FF3062" }}>
                          {item.isActivated ? "Activated" : "Deactivated"}
                        </p>
                        <div className="rules-dropdown item d-flex position-relative justify-content-center">
                          <FontAwesomeIcon
                            className="dropdown-toggle devices-dropdown-img px-3"
                            icon={faEllipsisVertical}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div className="dropdown-menu rules-dropdown-div">
                            <div className="sub1-rules-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                              <div className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleAddVehicle(item)}
                              >
                                <img src="/assets/AddCar.svg" alt="none" />
                                <p className="mb-0 ms-3">Add Vehicle</p>
                              </div>
                              <div className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleEditRule(item)}
                              >
                                <img src="/assets/edit.svg" alt="none" />
                                <p className="mb-0 ms-3">Edit</p>
                              </div>
                              {(item.type == RuleTypes.Custom || item.type == RuleTypes.Geofence) &&
                                <div className="d-flex align-items-center cursor-pointer action-item"
                                  onClick={() => handleRemoveRule(item)}
                                >
                                  <img src="/assets/remove.svg" alt="none" />
                                  <p className="mb-0 ms-3">Remove</p>
                                </div>
                              }
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleUpdateStatus(item.isActivated, item._id, item.name)}
                              >
                                <img src={item.isActivated ? "/assets/deactivate.svg" : "/assets/Activate.svg"} alt="none" />
                                <p className="mb-0 ms-3">{item.isActivated ? "Deactivate" : "Activate"}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="tab mobile-title mb-4">
            <div
              aria-current="page"
              className="px-3 d-flex justify-content-center py-2"
              style={{ color: "white", backgroundColor: "#1A2678", borderRadius: "30px", }}
            >
              <div className="d-flex content align-items-center">
                <FontAwesomeIcon className="me-4" icon={faFileCircleCheck} />
                Rules
              </div>
            </div>
          </div>
          <div className="sub1-div1 d-flex justify-content-between align-items-end w-100 p-0 my-3">
            <p className="px-3 text-white d-flex justify-content-evenly align-items-center mb-0">
              Total Rules <span className="ms-3">{filteredData?.length}</span>
            </p>
            <div className="d-flex">
              <div
                className="tab-button d-flex justify-content-evenly align-items-center mx-1"
                onClick={handleAddRule}
              >
                <img src="/assets/addbtn.svg" alt="none" />
                <button>Add rule</button>
              </div>
            </div>
          </div>
          <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 px-4 w-100">
            <div className="sub1-subsub1-sub2-div1 d-flex align-items-center p-2" onClick={() => setSearchFold(!searchFold)}>
              <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
              <span>Search rule</span>
              <img
                className="ms-auto accordion"
                style={!searchFold ? { transform: "rotate(180deg)" } : { transform: "none" }}
                src="/assets/arrow-down.png"
                alt="Arrow Down"
              />
            </div>
            {!searchFold && (
              <div className="sub2-subsub1-sub2-div1 d-flex flex-column px-0">
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-select"
                      placeholder="Rule Type"
                      options={Object.values(RuleTypes).map((type, index) => ({
                        label: type, value: type
                      }))}
                      value={searchRuleText}
                      onChange={handleRule}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-select"
                      placeholder="Device Type"
                      options={[{ label: DeviceType.Teltonika, value: DeviceType.Teltonika }, { label: DeviceType.Ruptela, value: DeviceType.Ruptela }]}
                      value={searchDeviceText}
                      onChange={handleDevice}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-select"
                      placeholder="I/O Pin"
                      options={IOPins.map(pin => ({ label: pin, value: pin }))}
                      value={searchIOPinText}
                      onChange={handleIOPin}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-select"
                      placeholder="Status"
                      options={[{ label: "Activated", value: "Activated" }, { label: "Deactivated", value: "Deactivated" }]}
                      value={searchStatusText}
                      onChange={handleStateColor}
                    />
                  </div>
                </div>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-auto py-1"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            )}
          </div>
          <div className={`sub2-div2 d-flex flex-column px-4 w-100 overflow-auto ms-0 ${searchFold && "folded"}`}>
            {filteredData?.map((item, index) => {
              return (
                <div id="import-rules" key={index}>
                  <p id="sub1-import-rules">{index + 1}</p>
                  <div className="subsub1-sub2-rules-div2 d-flex flex-column align-items-center py-2 px-3">
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Rule Type</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.name}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Device Type</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item.isTeltonika ? DeviceType.Teltonika : ""}
                        {item.isTeltonika && item.isRuptela ? "/" : ""}
                        {item.isRuptela ? DeviceType.Ruptela : ""}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">I/O Pin</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.ioPin}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Pin Status</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {
                          (item.type == RuleTypes.Custom && item.ioPin?.startsWith("D")) ? (item.pinStatus == 1 ? "High" : item.pinStatus == 2 ? "Both" : "Low") :
                            (item.type == RuleTypes.Custom && item.ioPin?.startsWith("A")) ? (item.pinStatus == 1 ? "Greater Than " : "Less Than ") + item.threshold : ""
                        }
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Vehicles</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {(!item.ignoreDevices || item.ignoreDevices?.length == 0) ? 'All' : (devices?.length - item.ignoreDevices?.length)}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Status</p>
                      <div className="mb-0 px-2 col-8 item justify-content-between">
                        <p
                          className="item mb-0 justify-content-start"
                          style={{ color: item.isActivated ? "#63D16E" : "#FF3062" }}
                        >
                          {item.isActivated ? "Activated" : "Deactivated"}
                        </p>
                        <div className="rules-dropdown d-flex position-relative justify-content-end">
                          <FontAwesomeIcon
                            className="dropdown-toggle devices-dropdown-img px-3"
                            icon={faEllipsisVertical}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div className="dropdown-menu rules-dropdown-div">
                            <div className="sub1-rules-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleAddVehicle(item)}
                              >
                                <img src="/assets/AddCar.svg" alt="none" />
                                <p className="mb-0 ms-3">Add Vehicle</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleEditRule(item)}
                              >
                                <img src="/assets/edit.svg" alt="none" />
                                <p className="mb-0 ms-3">Edit</p>
                              </div>
                              {(item.type == RuleTypes.Custom || item.type == RuleTypes.Geofence) &&
                                <div
                                  className="d-flex align-items-center cursor-pointer action-item"
                                  onClick={() => handleRemoveRule(item)}
                                >
                                  <img src="/assets/remove.svg" alt="none" />
                                  <p className="mb-0 ms-3">Remove</p>
                                </div>
                              }
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleUpdateStatus(item.isActivated, item._id, item.name)}
                              >
                                <img src={item.isActivated ? "/assets/deactivate.svg" : "/assets/Activate.svg"} alt="none" />
                                <p className="mb-0 ms-3">{item.isActivated ? "Deactivate" : "Activate"}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <AssignVehicleRule
        isShowModal={isShowVehicleModal}
        rule={selectedRule}
        onClose={(result) => {
          if (result) {
            sendRulesUpdatedMsg();
            getList();
          }
          setIsShowVehicleModal(false);
        }}
      />

      <CustomRuleEdit
        isShowModal={isShowCustomEditModal}
        rule={selectedRule}
        onClose={(result) => {
          if (result) {
            sendRulesUpdatedMsg();
            getList();
          }
          setIsShowCustomEditModal(false);
        }}
      />

      <BuiltinRuleEdit
        isShowModal={isShowBuiltinEditModal}
        rule={selectedRule}
        onClose={(result) => {
          if (result) {
            sendRulesUpdatedMsg();
            getList();
          }
          setIsShowBuiltinEditModal(false);
        }}
      />

    </div>
  );
};

export default Rules;
