import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelSleepMode = ({token, deviceImei, commandResponse}) => {
  
  const [sleepMode, setSleepMode] = useState("0");
  const [updatedDate, setUpdatedDate] = useState("")

  const sendSleepMode = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.SleepMode,
      params: {
        mode: sleepMode
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.SleepMode,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
      setUpdatedDate(formatDateTime(response.updatedAt));
      setSleepMode(response.sleepMode);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.SleepMode) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Sleep Mode"
      description="Device power saving mode setup. In sleep mode module reduces level of power consumption by turning GPS module to sleep. In deep sleep mode module turns GPS module to sleep and device is deregistered from GSM network (device do not receive SMS in deep sleep). In Online Deep Sleep mode device works as in Deep Sleep mode, but without deregistering from GSM network."
      date={updatedDate}
      onSendAction={sendSleepMode}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Sleep Mode"
            value={sleepMode}
            onChange={(value) => setSleepMode(value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Disabled" },
              { value: "1", label: "Enable" },
            ]}
          />
        </div>
      )} />
  );
};

export default PanelSleepMode;
