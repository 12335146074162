import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelSMSEvent = ({token, deviceImei, commandResponse}) => {
  
  const [hemisphere, setHemisphere] = useState("")
  const [timezone, setTimezone] = useState("")
  const [halfTimeZone, setHalfTimeZone] = useState("");
  // const [halfTimeZoneCheck, setHalfTimeZoneCheck] = useState(false);

  const [updatedDate, setUpdatedDate] = useState("")

  const sendSmsEventTimezone = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.SmsEventTimeZone,
      params: {
        zone: timezone
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.SmsEventTimeZone,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setHemisphere(response.hemisphere);
    setTimezone(response.timezone);
    setHalfTimeZone(response.halfTimeZone);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.SmsEventTimeZone) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="SMS event Time Zone"
      description=""
      date={updatedDate}
      onSendAction={sendSmsEventTimezone}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <TextInput
            name="Hemisphere"
            tooltip="Hemisphere: eastern or western"
            type="text"
            value={hemisphere}
            onChange={(value) => setHemisphere(value)} />
          <TextInput
            name="Time Zone"
            tooltip="Time zone offset from 0 to 12"
            suffix="Hours"
            type="number"
            value={timezone}
            onChange={(value) => setTimezone(value)} />
          <SelectBox 
            name="Half Time Zone"
            value={halfTimeZone}
            onChange={(value) => setHalfTimeZone(value)}
            options={[ "", 0, 15, 30, 45 ]}
            suffix={"Minutes"}
            tooltip={"Additional time zone offset"}
            />

          {/* <input type="checkbox" checked={halfTimeZoneCheck} onChange={(checked) => setHalfTimeZoneCheck(checked)} /> */}
        </div>
      )} />
  );
};

export default PanelSMSEvent;
