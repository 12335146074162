import { useEffect, useState } from "react";
import Try from "../../commads-settings/try/Try";

import "./TextInput.css";
import { Select } from "antd";

const SelectBox = ({ name, tooltip, options=[], value, onChange }) => {

    const [isFocused, setIsFocused] = useState(false);
    const [label, setLabel] = useState("");

    const handleChange = (value) => {
        if (typeof options[0] != "object") {
            setLabel(value);
        } else {
            for (let i = 0; i < options.length; i++) {
                if (options[i].value == value) {
                    setLabel(options[i].label);
                    break;
                }
            }
        }
        onChange(value);
    }

    useEffect(() => {
        if (typeof options[0] != "object") {
            setLabel(value);
        } else {
            for (let i = 0; i < options.length; i++) {
                if (options[i].value == value) {
                    setLabel(options[i].label);
                    break;
                }
            }
        }
    }, [value]);

    return (
        <div className="w-100 d-flex justify-content-center align-items-center mb-3 position-relative">
            <div className={`text-input-container d-flex flex-column justify-content-between position-relative align-items-center ${isFocused && 'highlight'}`}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            >
                <div className="w-100 text-secondary">{name}</div>
                <div className="w-100">{label}&nbsp;</div>

                <Select
                  className="position-absolute w-100 h-100 border-0"
                  placeholder=""
                  options={options.map((item, index) => ({
                    label: (typeof item == "object" ? item.label : item), 
                    value: (typeof item == "object" ? item.value : item)
                  }))}
                  value={""}
                  onChange={handleChange}
                />
            </div>
            {tooltip && 
                <div className="position-absolute" style={{right: 0}}>
                    <Try text={tooltip} />
                </div>
            }
        </div>
    );
}

export default SelectBox;