import React, { useEffect, useState } from "react";
import { CommandPriorities, CommandDoutControls, cmdType, getCommandPhones } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import Switch from "../../commons/switch/Switch";
import SelectBox from "../../commons/input/SelectBox";
import TextInput from "../../commons/input/TextInput";
import Try from "../try/Try";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const PanelGreenDriving = ({token, deviceImei, commandResponse, gsmNumbers}) => {
  
  const [priority, setPriority] = useState("");
  const [drive_max_acc, setMaxAcc] = useState("");
  const [drive_max_brk, setMaxBrk] = useState("");
  const [drive_max_angle, setMaxAngle] = useState("");
  const [drive_source, setDriveSource] = useState("");
  const [drive_duration_check, setDriveDurationCheck] = useState(false);
  const [drive_send_sms, setDriveSendSms] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [smsText, setSmsText] = useState("");
  const [outputControl, setOutputControl] = useState("")
  const [onDuration, setOnDuration] = useState("")
  const [offDuration, setOffDuration] = useState("")

  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendGreenDriving = async () => {
    return sendCommonRequest(cmdType.GreenDriving, {
      priority: priority,
      mxAcc: drive_max_acc,
      mxBrak: drive_max_brk,
      mxAng: drive_max_angle,
      src: drive_source,
      duration: drive_duration_check ? "1" : "0",
      phoneNo: phoneNumber,
      sms: smsText,
      outputControl: outputControl,
      doutOnDuration: onDuration,
      doutOffDuration: offDuration,
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.GreenDriving,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
      setPriority(response.priority);
      setMaxAcc(parseFloat(response.mxAcc));
      setMaxBrk(parseFloat(response.mxBrak));
      setMaxAngle(parseFloat(response.mxAng));
      setDriveSource(response.src);
      setDriveDurationCheck(parseInt(response.duration) === 1);
      setDriveSendSms(parseInt(response.phoneNo) > 0);
      setPhoneNumber(response.phoneNo);
      setSmsText(response.sms);
      setOutputControl(response.outputControl);
      setOnDuration(response.doutOnDuration);
      setOffDuration(response.doutOffDuration);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.GreenDriving) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="Green Driving"
      description="Green driving scenario settings"
      date={updatedDate}
      onSendAction={sendGreenDriving}
      onRefreshAction={refreshAction}
      defaultOpen={true}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Priority"
            value={priority}
            onChange={(value) => setPriority(value)}
            options={CommandPriorities}
            tooltip={"Green driving detection scenario priority"}
          />
          <TextInput
            name="Max Acceleration"
            tooltip="Max acceleration which can be reached without triggering harsh acceleration event"
            type="number"
            suffix="m/sec^2"
            value={drive_max_acc}
            onChange={(value) => setMaxAcc(value)} />
          <TextInput
            name="Max Braking"
            tooltip="Max braking which can be reached without triggering harsh braking event"
            type="number"
            suffix="m/sec^2"
            value={drive_max_brk}
            onChange={(value) => setMaxBrk(value)} />
          <TextInput
            name="Max Angle"
            tooltip="Max angle which can be reached without triggering harsh cornering event"
            type="number"
            suffix="m/sec^2"
            value={drive_max_angle}
            onChange={(value) => setMaxAngle(value)} />
          <SelectBox
            name="Source"
            value={drive_source}
            onChange={(value) => setDriveSource(value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "GPS" },
              { value: "1", label: "Accelerometer" },
            ]}
          />
          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={drive_duration_check}
              onChange={(checked) => setDriveDurationCheck(checked)}
              suffix={"Duration"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Enable/disable duration"} />
            </div>
          </div>
          <div className="input-element border-0 w-100 position-relative d-flex justify-content-start mb-3" style={{ paddingLeft: '8rem' }}>
            <Switch
              value={drive_send_sms}
              onChange={(checked) => setDriveSendSms(checked)}
              prefix={<span className="pe-5 me-5">Send SMS</span>}
              suffix={"ON"}
            />
            <div className="position-absolute" style={{ right: '0' }}>
              <Try text={"Enable sending SMS of green driving event"} />
            </div>
          </div>
          
          {drive_send_sms && (
            <>
              <SelectBox
                name="Phone Number"
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                options={getCommandPhones(gsmNumbers)}
              />
              <TextInput
                name="SMS Text"
                type="text"
                value={smsText}
                onChange={(value) => setSmsText(value)} />
            </>
          )}

          <SelectBox
            name="Output Control"
            value={outputControl}
            onChange={(value) => setOutputControl(value)}
            options={CommandDoutControls}
          />
          <TextInput
            name="DOUT ON Duration"
            type="number"
            suffix="ms"
            value={onDuration}
            options={{ min: 100, max: 5000 }}
            onChange={(value) => setOnDuration(value)} />
          <TextInput
            name="DOUT OFF Duration"
            type="number"
            suffix="ms"
            value={offDuration}
            options={{ min: 0, max: 5000 }}
            onChange={(value) => setOffDuration(value)} />
        </div>
      )} />
  );
};

export default PanelGreenDriving;
