import React, { useEffect, useState } from "react";
import { cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import TextInput from "../../commons/input/TextInput";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import { sendCommandMsg } from "../../../socket";

const _protocols1 = [ 
  { value: "", label: "" },
  { value: "0", label: "TCP" },
  { value: "1", label: "UDP" },
  { value: "2", label: "MQTT" },
];
const _protocols2 = [ 
  { value: "", label: "" },
  { value: "0", label: "TCP" },
  { value: "2", label: "MQTT" },
];

const PanelGPRSSecondServerSetup = ({token, deviceImei, onMQTTSelected, commandResponse}) => {
  
  const [gprsSMode, setGprsSMode] = useState("");
  const [gprsSHost, setGprsSHost] = useState("");
  const [gprsSPort, setGprsSPort] = useState("");
  const [gprsSProtocol, setGprsSProtocol] = useState("UDP");
  const [tlsEncryption, setTlsEncryption] = useState(false);
  
  const [updatedDate, setUpdatedDate] = useState("")

  const [protocols, setProtocols] = useState(_protocols1);

  const sendGPRSSecondarySetup = async () => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.SecondaryGprsServerSetup,
      params: {
        host: gprsSHost,
        port: gprsSPort,
        mode: gprsSMode,
        protocol: gprsSProtocol,
        tlsEncryption: tlsEncryption ? 1 : 0
      },
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.SecondaryGprsServerSetup,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }

  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setGprsSMode(response.mode);
    setGprsSHost(response.host);
    setGprsSPort(response.port);
    setGprsSProtocol(response.protocol);
    setTlsEncryption(parseInt(response.tlsEncryption) == 1);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.SecondaryGprsServerSetup) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  useEffect(() => {
    setProtocols(tlsEncryption ? _protocols2 : _protocols1);
    if (tlsEncryption && gprsSProtocol == 1) {
      setGprsSProtocol("0");
    }
  }, [tlsEncryption]);

  useEffect(() => {
    onMQTTSelected(gprsSProtocol == 2);
  }, [gprsSProtocol]);

  return (
    <CommandSettingPanel
      title="Secondary GPRS Server Setup"
      description=""
      date={updatedDate}
      onSendAction={sendGPRSSecondarySetup}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox 
            name="Mode"
            value={gprsSMode}
            onChange={(value) => setGprsSMode(value)}
            options={[ 
              { value: "", label: "" },
              { value: "0", label: "Disable" },
              { value: "1", label: "Backup" },
              { value: "2", label: "Duplicate" },
              { value: "3", label: "EGTS" },
            ]}
            />
          <TextInput
            name="Host"
            tooltip=""
            type="text"
            value={gprsSHost}
            onChange={(value) => setGprsSHost(value)} />
          <TextInput
            name="Port"
            tooltip=""
            type="number"
            value={gprsSPort}
            onChange={(value) => setGprsSPort(value)} />
          {gprsSMode != 3 &&
            <SelectBox 
              name="Server Protocol"
              value={gprsSProtocol}
              onChange={(value) => { setGprsSProtocol(value); }}
              options={protocols}
              />
          }
          <div className="input-element border-0 d-flex justify-content-between align-items-center">
            <span>TLS Encryption</span>
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" className="btn-check" name="btnradio_sgs" id="sgs_btnradio1" checked={!tlsEncryption} onClick={() => setTlsEncryption(false)} />
              <label className="btn " htmlFor="sgs_btnradio1" style={{ width: 70 }}>None</label>

              <input type="radio" className="btn-check" name="btnradio_sgs" id="sgs_btnradio2" checked={tlsEncryption} onClick={() => setTlsEncryption(true)} />
              <label className="btn " htmlFor="sgs_btnradio2">TLS/DRLS</label>
            </div>
          </div>
        </div>
      )} />
  );
};

export default PanelGPRSSecondServerSetup;
