import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { CSVLink } from "react-csv";
import { faMicrochip, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getCompanies, removeDevice } from "../../services/axios";
import { DeviceModels } from "../../utils/mockup";

import "./Devices.css";
import { formatDateTime, getGooglePosUrl, showConfirmEx } from "../../utils/globals";
import { Dropdown, Select, notification } from "antd";
import { getDevices } from "../../redux/actions/devices";
import { DeviceType, isIgnoreVehicleType } from "../../common/common";

const Devices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const user = useSelector((state) => state.auth.user);

  const devices = useSelector((state) => state.devicesList.devices);
  const [companies, setCompanies] = useState([]);

  const [stateColor, setStateColor] = useState("#7A7D8B");
  const [searchPlateText, setSearchPlateText] = useState("");
  const [searchImeiText, setSearchImeiText] = useState("");
  const [searchDeviceText, setSearchDeviceText] = useState(null);
  const [searchStatusText, setSearchStatusText] = useState(null);
  const [searchModelText, setSearchModelText] = useState(null);
  const [devicesData, setDevicesData] = useState(devices);
  const [searchFold, setSearchFold] = useState(true);

  useEffect(() => {
    (async () => {
      dispatch(getDevices(false));
    })();

    getCompanies()
      .then(res => {
        if (res.status == 200) {
          setCompanies(res.data.result);
        }
      })
      .catch(err => { });
  }, []);

  useEffect(() => {
    setDevicesData(
      devices?.filter((item) => {
        return (
          (searchPlateText == "" || item.vehicleName.toLocaleLowerCase().includes(searchPlateText.toLocaleLowerCase())) &&
          (searchImeiText == "" || item.deviceImei.toLocaleLowerCase().includes(searchImeiText.toLocaleLowerCase())) &&
          (!searchDeviceText || item.deviceType === searchDeviceText) &&
          (!searchStatusText ||
            (searchStatusText == "Connected" && item.connected) ||
            (searchStatusText == "Not Connected" && !item.connected)
          ) &&
          (searchModelText == "" || searchModelText == null || item.deviceModel === searchModelText)
        );
      })
    );
  }, [
    searchPlateText,
    searchImeiText,
    searchDeviceText,
    searchStatusText,
    searchModelText,
    devices,
  ]);

  const handlePlate = (event) => {
    setSearchPlateText(event.target.value);
  };

  const handleImei = (event) => {
    setSearchImeiText(event.target.value);
  };

  const handleDevice = (value) => {
    setSearchDeviceText(value);
  };

  const handleModel = (value) => {
    setSearchModelText(value);
  };

  const handleClear = () => {
    setSearchPlateText("");
    setSearchImeiText("");
    setSearchDeviceText("");
    setSearchModelText(null);
    setSearchStatusText("");
    setStateColor("grey");
  };

  const handleViewDevice = (item) => {
    navigate(item?.vehicleType == 'ACS' ? "/Devices/ViewDeviceAC" : "/Devices/ViewDevice",
      {
        state: {
          vehicleType: item?.vehicleType,
          plate: item?.vehicleName,
          imei: item?.deviceImei,
          device: item?.deviceType,
          model: item?.deviceModel,
          sensors: item?.sensors,
          mobile: item?.mobileNo,
          company: item?.company,
        },
      });
  };

  const handleEditDevice = (item) => {
    navigate(item?.vehicleType == 'ACS' ? "/Devices/EditDeviceAC" : "/Devices/EditDevice",
      {
        state: {
          vehicleType: item?.vehicleType,
          plate: item?.vehicleName,
          imei: item?.deviceImei,
          device: item?.deviceType,
          model: item?.deviceModel,
          sensors: item?.sensors,
          mobile: item?.mobileNo,
          company: item?.company,
          companies: companies
        },
      });
  };

  const handleLocationClick = (data) => {
    window.open(getGooglePosUrl(data?.lat, data?.lng), "_blank");
  };

  const handleRemoveDevice = async (imei, name) => {
    showConfirmEx(`Are you sure to remove vehicle No. ${name}?`)
      .then(async () => {
        const res = await removeDevice({ deviceImei: imei });
        if (res?.status === 200) {
          notification.success({
            description: "Device has been removed successfully!",
          });
          dispatch(getDevices(false));
        }
      })
      .catch(() => { });
  };

  return (
    <div className="devices-main">
      {!isMobile ? (
        <div className="devices-div1">
          <div className="sub1-div1">
            <p className="px-5">
              Total Devices<span className="ms-3">{devicesData?.length}</span>
            </p>
            <div className="subsub1-sub1-div1">
              <CSVLink
                data={devicesData}
                filename="Devices"
                style={{ textDecoration: "none" }}
              >
                <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                  <img src="/assets/export.svg" alt="none" />
                  <button className="ms-2">Export</button>
                </div>
              </CSVLink>
              <Dropdown
                menu={{}}
                trigger={"click"}
                placement="bottomRight"
                dropdownRender={(menu) => {
                  return (
                    <div className="d-flex flex-column bg-white p-3 gap-2 portal-report-types">
                      {(!isIgnoreVehicleType(user.company?.ignoreVehicleTypes, 'Car') || !isIgnoreVehicleType(user.company?.ignoreVehicleTypes, 'Bike')) &&
                        <button className="text-start" onClick={() => navigate("/Devices/AddDevice", { state: { companies } })}>Vehicle</button>
                      }
                      {!isIgnoreVehicleType(user.company?.ignoreVehicleTypes, 'ACS') &&
                        <button className="text-start" onClick={() => navigate("/Devices/AddDeviceAC", { state: { companies } })}>Access Controller</button>
                      }
                      {!isIgnoreVehicleType(user.company?.ignoreVehicleTypes, 'Router') &&
                        <button className="text-start" onClick={() => { }}>Router</button>
                      }
                      {!isIgnoreVehicleType(user.company?.ignoreVehicleTypes, 'Box') &&
                        <button className="text-start" onClick={() => { }}>Box</button>
                      }
                    </div>
                  );
                }}
              >
                <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                  <img src="/assets/addbtn.svg" alt="none" />
                  <button>Add Device</button>
                </div>
              </Dropdown>
            </div>
          </div>
          <div className="sub2-div1">
            <div className="subsub1-sub2-div1 py-2">
              <div className="sub1-subsub1-sub2-div1 my-2">
                <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
                <span>Search device</span>
              </div>
              <div className="sub2-subsub1-sub2-div1">
                <input
                  className="field-input"
                  type="text"
                  placeholder="Plate No./Device"
                  value={searchPlateText}
                  onChange={handlePlate}
                />
                <input
                  className="field-input"
                  type="numder"
                  placeholder="IMEI"
                  value={searchImeiText}
                  onChange={handleImei}
                />
                <Select
                  allowClear
                  className="field-select"
                  placeholder="Device"
                  options={[
                    { label: DeviceType.Teltonika, value: DeviceType.Teltonika },
                    { label: DeviceType.Ruptela, value: DeviceType.Ruptela },
                    { label: DeviceType.Biosense, value: DeviceType.Biosense },
                  ]}
                  value={searchDeviceText}
                  onChange={handleDevice}
                />
                <Select
                  showSearch
                  allowClear
                  className="field-select"
                  placeholder="Model"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={DeviceModels.filter(item => (searchDeviceText == "" || item.type == searchDeviceText)).map((item) => ({
                    label: item.device, value: item.device
                  }))}
                  value={searchModelText}
                  onChange={(value) => handleModel(value)}
                />
                <Select
                  allowClear
                  className="field-select"
                  placeholder="Status"
                  options={[
                    { label: "Connected", value: "Connected" },
                    { label: "Not Connected", value: "Not Connected" },
                  ]}
                  value={searchStatusText}
                  onChange={value => setSearchStatusText(value)}
                />
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="subsub1-sub1-devices-div2 py-3">
                <p className="mb-0 ps-5">Plate No./Device</p>
                <p className="mb-0">Device</p>
                <p className="mb-0">Model</p>
                <p className="mb-0">IMEI No.</p>
                <p className="mb-0">Last Location</p>
                <p className="mb-0">Company</p>
                <p className="mb-0">Status</p>
                <p className="mb-0"></p>
              </div>
              <div className="sub2-div2 overflow-auto">
                {devicesData?.map((item, index) => {
                  return (
                    <div id="import-devices" key={index}>
                      <p id="sub1-import-devices">{index + 1}</p>
                      <div className="subsub1-sub2-devices-div2">
                        <p className="item ps-5">{item?.vehicleName}</p>
                        <p className="item">{item?.deviceType}</p>
                        <p className="item">{item?.deviceModel}</p>
                        <p className="item">{item?.deviceImei}</p>
                        <div className="item">
                          <div className="import-gps-time-devices">
                            <p>
                              &nbsp;GPS:{" "}
                              <span
                                className="ms-1 cursor-pointer"
                                onClick={() => handleLocationClick(item)}
                                style={{ color: "rgb(133 29 209)" }}
                              >
                                {item.lat + ", " + item.lng}
                              </span>
                            </p>
                            <p>
                              Time:{" "}
                              <span className="ms-1">
                                {formatDateTime(item?.gpsFixedDate)}
                              </span>
                            </p>
                          </div>
                        </div>
                        <p className="item">{item?.company?.name}</p>
                        <p className="item" style={{ color: !item?.connected ? "#FF3062" : "#63D16E" }}>
                          {item?.connected ? "Connected" : "Not Connected"}
                        </p>
                        <div className="devices-dropdown item d-flex position-relative">
                          <FontAwesomeIcon
                            className="dropdown-toggle devices-dropdown-img px-3"
                            icon={faEllipsisVertical}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div className="dropdown-menu devices-dropdown-div">
                            <div className="sub1--devices-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleViewDevice(item)}
                              >
                                <img src="/assets/view.svg" alt="none" />
                                <p className="mb-0 ms-3">View</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleEditDevice(item)}
                              >
                                <img src="/assets/edit.svg" alt="none" />
                                <p className="mb-0 ms-3">Edit</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleRemoveDevice(item?.deviceImei, item?.vehicleName)}
                              >
                                <img src="/assets/remove.svg" alt="none" />
                                <p className="mb-0 ms-3">Remove</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="tab mobile-title mb-4">
            <div
              aria-current="page"
              className="px-3 d-flex justify-content-center py-2"
              style={{ color: "white", backgroundColor: "#1A2678", borderRadius: "30px", }}
            >
              <div className="d-flex content align-items-center">
                <FontAwesomeIcon className="me-4" icon={faMicrochip} />
                Devices
              </div>
            </div>
          </div>
          <div className="sub1-div1 d-flex justify-content-between align-items-end w-100 p-0 my-3">
            <p className="px-3 text-white d-flex justify-content-evenly align-items-center mb-0">
              Total Devices <span className="ms-3">{devicesData?.length}</span>
            </p>
            <div className="d-flex">
              <CSVLink
                data={devicesData}
                filename="Devices"
                style={{ textDecoration: "none" }}
              >
                <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                  <img src="/assets/export.svg" alt="none" />
                  <button>Export</button>
                </div>
              </CSVLink>
              <Dropdown
                menu={{}}
                trigger={"click"}
                placement="bottomRight"
                dropdownRender={(menu) => {
                  return (
                    <div className="d-flex flex-column bg-white p-3 gap-2 portal-report-types">
                      {(!isIgnoreVehicleType(user.company?.ignoreVehicleTypes, 'Car') || !isIgnoreVehicleType(user.company?.ignoreVehicleTypes, 'Bike')) &&
                        <button className="text-start" onClick={() => navigate("/Devices/AddDevice", { state: { companies } })}>Vehicle</button>
                      }
                      {!isIgnoreVehicleType(user.company?.ignoreVehicleTypes, 'ACS') &&
                        <button className="text-start" onClick={() => navigate("/Devices/AddDeviceAC", { state: { companies } })}>Access Controller</button>
                      }
                      {!isIgnoreVehicleType(user.company?.ignoreVehicleTypes, 'Router') &&
                        <button className="text-start" onClick={() => { }}>Router</button>
                      }
                      {!isIgnoreVehicleType(user.company?.ignoreVehicleTypes, 'Box') &&
                        <button className="text-start" onClick={() => { }}>Box</button>
                      }
                    </div>
                  );
                }}
              >
                <div className="tab-button d-flex justify-content-center align-items-center mx-1">
                  <img src="/assets/addbtn.svg" alt="none" />
                  <button>Add Device</button>
                </div>
              </Dropdown>
            </div>
          </div>
          <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 px-4 w-100">
            <div className="sub1-subsub1-sub2-div1 d-flex align-items-center p-2" onClick={() => setSearchFold(!searchFold)}>
              <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
              <span>Search device</span>
              <img
                className="ms-auto accordion"
                style={!searchFold ? { transform: "rotate(180deg)" } : { transform: "none" }}
                src="/assets/arrow-down.png"
                alt="Arrow Down"
              />
            </div>
            {!searchFold && (
              <div className="sub2-subsub1-sub2-div1 d-flex flex-column px-0">
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="text"
                      placeholder="Plate No./Device"
                      value={searchPlateText}
                      onChange={handlePlate}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="numder"
                      placeholder="IMEI"
                      value={searchImeiText}
                      onChange={handleImei}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-select"
                      placeholder="Device"
                      options={[
                        { label: DeviceType.Teltonika, value: DeviceType.Teltonika },
                        { label: DeviceType.Ruptela, value: DeviceType.Ruptela },
                        { label: DeviceType.Biosense, value: DeviceType.Biosense },
                      ]}
                      value={searchDeviceText}
                      onChange={handleDevice}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <Select
                      showSearch
                      allowClear
                      className="field-select"
                      placeholder="Select Model"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={DeviceModels.filter(item => (searchDeviceText == "" || item.type == searchDeviceText)).map((item) => ({
                        label: item.device, value: item.device
                      }))}
                      value={searchModelText}
                      onChange={(value) => handleModel(value)}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <Select
                      allowClear
                      className="field-select"
                      placeholder="Status"
                      options={[
                        { label: "Connected", value: "Connected" },
                        { label: "Not Connected", value: "Not Connected" },
                      ]}
                      value={searchStatusText}
                      onChange={value => setSearchStatusText(value)}
                    />
                  </div>
                </div>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-auto py-1"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            )}
          </div>
          <div className={`sub2-div2 d-flex flex-column px-4 w-100 overflow-auto ms-0 ${searchFold && "folded"}`}>
            {devicesData?.map((item, index) => {
              return (
                <div id="import-devices" key={index}>
                  <p id="sub1-import-devices">{index + 1}</p>
                  <div className="subsub1-sub2-devices-div2 d-flex flex-column align-items-center py-2 px-3">
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Plate No./Device</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.vehicleName}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Device</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.deviceType}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Model</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.deviceModel}
                      </p>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">IMEI</p>
                      <p className="mb-0 px-2 col-8 item justify-content-start">
                        {item?.deviceImei}
                      </p>
                    </div>
                    <div className="d-flex flex-row align-items-start w-100">
                      <p className="mb-0 px-2 col-4 text-white">
                        Last Location
                      </p>
                      <div className="import-gps-time-devices mb-0 px-2 col-8 item justify-content-start flex-column">
                        <p>
                          GPS:{" "}
                          <span className="ms-1 cursor-pointer" onClick={() => handleLocationClick(item)}>
                            {item.lat + ", " + item.lng}
                          </span>
                        </p>
                        <p>
                          Time:{" "}
                          <span className="ms-1">
                            {formatDateTime(item?.gpsFixedDate)}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex w-100">
                      <p className="mb-0 px-2 col-4 text-white">Status</p>
                      <div className="mb-0 px-2 col-8 item justify-content-between">
                        <p
                          className="item mb-0 justify-content-start"
                          style={{ color: !item?.connected ? "#FF3062" : "#63D16E" }}
                        >
                          {item?.connected ? "Connected" : "Not Connected"}
                        </p>
                        <div className="devices-dropdown d-flex position-relative">
                          <FontAwesomeIcon
                            className="dropdown-toggle devices-dropdown-img px-3"
                            icon={faEllipsisVertical}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div className="dropdown-menu devices-dropdown-div">
                            <div className="sub1--devices-dropdown-div mx-4 d-flex flex-column justify-content-evenly h-100">
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() => handleViewDevice(item)}
                              >
                                <img src="/assets/view.svg" alt="none" />
                                <p className="mb-0 ms-3">View</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() => handleEditDevice(item)}
                              >
                                <img src="/assets/edit.svg" alt="none" />
                                <p className="mb-0 ms-3">Edit</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() => handleRemoveDevice(item?.deviceImei, item?.vehicleName)}
                              >
                                <img src="/assets/remove.svg" alt="none" />
                                <p className="mb-0 ms-3">Remove</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Devices;
