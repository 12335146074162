import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PhoneNumberInput from "../../commons/input/PhoneNumberInput";
import { Sensors } from "../../../utils/mockup";
import { DeviceType } from "../../../common/common";

const ViewDevice = () => {
  const navigate = useNavigate();
  const getData = useLocation();

  const [step, setStep] = useState(0);

  const [formData, setFormData] = useState({
    vehicleType: getData.state.vehicleType,
    plate: getData.state.plate,
    imei: getData.state.imei,
    deviceType: getData.state.device,
    model: getData.state.model,
    sensors: getData.state.sensors ?? [""],
    mobileNo: getData.state.mobile,
    company: getData.state.company?.name,
  });

  return (
    <div className="add-device-main w-100 h-100">
      <p>View Vehicle</p>
      <div className="add-device-div1 d-flex flex-column align-items-center p-5 mx-5">
        <div className="right-subsub1-sub1-add-device-div1 p-5 mb-4 d-flex flex-column align-items-center" style={{ width: "102rem", maxWidth: "100%" }}>
          <div className="step-wrapper mb-3">
            <div className={`rounded-circle step ${step >= 0 ? 'active' : ''}`}>Device</div>
            <div className="line"></div>
            <div className={`rounded-circle step ${step >= 1 ? 'active' : ''}`}>Sensors/<br />Accessories</div>
            <div className="line"></div>
            <div className={`rounded-circle step`}>Finish</div>
          </div>
          <div className={`${step === 0 ? 'd-flex' : 'd-none'} flex-column gap-3 `} style={{ minHeight: "51rem" }}>
            <div className="add-input-container d-flex flex-column">
              <label>Device IMEI</label>
              <input
                className="normal-input"
                value={formData.imei}
                name="imei"
                type="number"
                onChange={() => { }}
                readOnly
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Device Type</label>
              <input
                className="normal-input"
                value={formData.deviceType}
                name="deviceType"
                type="text"
                onChange={() => { }}
                readOnly
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Device Model</label>
              <input
                className="normal-input"
                value={formData.model}
                name="model"
                type="text"
                onChange={() => { }}
                readOnly
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>SIM No.</label>
              <div className='input-item'>
                <PhoneNumberInput
                  value={formData.mobileNo}
                  onChange={() => { }}
                  readOnly
                />
              </div>
            </div>
            <div className="add-input-container d-flex flex-column position-relative">
              <label>Vehicle Type</label>
              <input
                className="normal-input"
                value={formData.vehicleType}
                name="vehicleType"
                type="text"
                onChange={() => { }}
                readOnly
              />
              {formData.vehicleType === "Car" ? (
                <img className="vehicle-icon position-absolute" src="/assets/Car.svg" alt="none" />
              ) : formData.vehicleType === "Bike" ? (
                <img className="vehicle-icon position-absolute" src="/assets/Bike.svg" alt="none" />
              ) : <></>
              }
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Plate No.</label>
              <input
                className="normal-input"
                value={formData.plate}
                name="plate"
                type="text"
                maxLength={13}
                onChange={() => { }}
                readOnly
              />
            </div>
            <div className="add-input-container d-flex flex-column">
              <label>Company</label>
              <input
                className="normal-input"
                value={formData.company}
                name="company"
                type="text"
                onChange={() => { }}
                readOnly
              />
            </div>
          </div>

          <div className={`${step === 1 ? 'd-flex' : 'd-none'} flex-column gap-3 `} style={{ minHeight: "51rem" }}>
            <p className="p-0 mt-3">External Sensors / Accessories</p>
            <div className="add-input-container d-flex flex-column">
              <input
                className="normal-input"
                value={Sensors.find(item => item.id == formData.sensors?.[0])?.label}
                name="sensor"
                type="text"
                onChange={() => { }}
                readOnly
              />
            </div>

            {formData.sensors?.length > 1 &&
              formData.sensors?.filter((sensor, index) => index > 0).map((sensor, index) => (
                <div className={`add-input-container d-flex flex-column position-relative`} key={index}>
                  <input
                    className="normal-input"
                    value={Sensors.find(item => item.id == sensor)?.label}
                    name="sensor"
                    type="text"
                    onChange={() => { }}
                    readOnly
                  />
                </div>
              ))
            }
          </div>
        </div>
        <div className="sub5-right-subsub1-sub1-add-device-div1 d-flex justify-content-center w-100">
          <button className='me-5' onClick={() => navigate("/Devices")}>Back</button>
          {step === 0 &&
            <button onClick={() => setStep(1)}>Next</button>
          }
        </div>
      </div>
    </div>
  );
};

export default ViewDevice;
