import React, { useEffect, useState } from "react";
import { CommandDoutControls, CommandPriorities, cmdType } from "../../../common/common";
import { commandSetting, getCommandSettings } from "../../../services/axios";

import CommandSettingPanel from "../../commons/panel/CommandSettingPanel";
import SelectBox from "../../commons/input/SelectBox";
import { formatDateTime } from "../../../utils/globals";
import Switch from "../../commons/switch/Switch";
import TextInput from "../../commons/input/TextInput";
import { sendCommandMsg } from "../../../socket";

const PanelSECO = ({token, deviceImei, commandResponse}) => {
  
  const [priority, setPriority] = useState("");
  const [eventOnly, setEventOnly] = useState(false);
  const [outputControl, setOutputControl] = useState("");
  const [speed, setSpeed] = useState("");
  const [speedPulse, setSpeedPulse] = useState("");
  const [speedPeriod, setSpeedPeriod] = useState("");
  const [movementTimeout, setMovementTimeout] = useState("");
  const [outputPulse, setOutputPulse] = useState("");
  const [onDuration, setOnDuration] = useState("");
  const [offDuration, setOffDuration] = useState("");

  const [updatedDate, setUpdatedDate] = useState("")


  const sendCommonRequest = async (type, params) => {
    const sendCommandData =
    {
      token: token,
      type: type,
      params: params,
      devImei: deviceImei
    }
    var res = await commandSetting(sendCommandData);
    if (res.status === 200) {
      sendCommandMsg(res.data.commandId);
    }
    return res;
  }

  const sendAction = async () => {
    return sendCommonRequest(cmdType.SECO, {
      priority: priority,
      eventOnly: eventOnly ? "1" : "0",
      outputControl: outputControl,
      speed: speed,
      speedPulse: speedPulse,
      speedPeriod: speedPeriod,
      movementTimeout: movementTimeout,
      outputPulse: outputPulse,
      onDuration: onDuration,
      offDuration: offDuration,
    })
  }

  const refreshAction = async (isLast = false) => {
    const sendCommandData =
    {
      token: token,
      type: cmdType.SECO,
      devImei: deviceImei,
      isLast: isLast,
    }
    var res = await getCommandSettings(sendCommandData);
    if (res.status === 200) {
      if (isLast) {
        setResponse(res.data);
      } else {
        sendCommandMsg(res.data.commandId);
      }
    }
  }
  
  const setResponse = (response) => {
    setUpdatedDate(formatDateTime(response.updatedAt));
    setPriority(response.priority);
    setEventOnly(parseInt(response.eventOnly) === 1);
    setOutputControl(response.outputControl);
    setSpeed(response.speed);
    setSpeedPulse(response.speedPulse);
    setSpeedPeriod(response.speedPeriod);
    setMovementTimeout(response.movementTimeout);
    setOutputPulse(response.outputPulse);
    setOnDuration(response.onDuration);
    setOffDuration(response.offDuration);
  }

  useEffect(() => {
    if (commandResponse?.cmdType == cmdType.SECO) {
      setResponse(commandResponse);
    }
  }, [commandResponse]);

  return (
    <CommandSettingPanel
      title="SECO"
      description="The secure engine cut-off(SECO) functionality is intended to stop the stolen vehicle regardless of thief's actions."
      date={updatedDate}
      onSendAction={sendAction}
      onRefreshAction={refreshAction}
      content={(
        <div className="d-flex flex-column align-items-center py-3 px-5">
          <SelectBox
            name="Scenario Priority"
            value={priority}
            onChange={(value) => setPriority(value)}
            options={CommandPriorities}
          />
          <div className="input-element border-0 position-relative d-flex justify-content-start mb-3">
            <Switch
              value={eventOnly}
              onChange={(checked) => setEventOnly(checked)}
              suffix={"Event Only"}
            />
          </div>
          <SelectBox
            name="Output Control"
            value={outputControl}
            onChange={(value) => setOutputControl(value)}
            options={CommandDoutControls}
          />
          <TextInput
            name="Speed"
            type="number"
            suffix="km/h"
            value={speed}
            options={{ min: 0, max: 255 }}
            onChange={(value) => setSpeed(value)} />
          <TextInput
            name="Speed Pulse Scenario"
            type="number"
            suffix="km/h"
            value={speedPulse}
            options={{ min: 0, max: 255 }}
            onChange={(value) => setSpeedPulse(value)} />
          <TextInput
            name="Speed Check Period"
            type="number"
            suffix="s"
            value={speedPeriod}
            options={{ min: 0, max: 65535 }}
            onChange={(value) => setSpeedPeriod(value)} />
          <TextInput
            name="Movement timeout"
            type="number"
            suffix="s"
            value={movementTimeout}
            options={{ min: 0, max: 65535 }}
            onChange={(value) => setMovementTimeout(value)} />
          <SelectBox
            name="Output Pulse"
            value={outputPulse}
            onChange={(value) => setOutputPulse(value)}
            options={[
              { value: "", label: "" },
              { value: "0", label: "Disable" },
              { value: "1", label: "Enable" },
            ]}
          />
          <TextInput
            name="DOUT ON Duration"
            type="number"
            suffix="ms"
            value={onDuration}
            options={{ min: 10, max: 5000 }}
            onChange={(value) => setOnDuration(value)} />
          <TextInput
            name="DOUT OFF Duration"
            type="number"
            suffix="ms"
            value={offDuration}
            options={{ min: 10, max: 5000 }}
            onChange={(value) => setOffDuration(value)} />
          
        </div>
      )} />
  );
};

export default PanelSECO;
